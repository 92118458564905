import React from "react";
import Uploader from "../components/uploader";
import ElementorContainer from "../components/ElementorContainer";
import Layout from "../components/Layout";

const UploadPage = () => {
  return (
    <Layout title="Get started">
      <ElementorContainer id="1008">
        <Uploader />
      </ElementorContainer>
    </Layout>
  );
};
export default UploadPage;