import React from 'react';
import ElementorContainer from '../components/ElementorContainer';
import Layout from '../components/Layout';
import TermsHero from '../components/terms/hero';
import TermsList from '../components/terms/terms';
const Terms = () => {
    return (
        <Layout title="Terms and Conditions">
                <ElementorContainer id="155">
                    <TermsHero/>
                    <TermsList/>
                </ElementorContainer>
        </Layout>
    )
}
export default Terms;