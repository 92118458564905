import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-d2a74d9 elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="d2a74d9"
      data-element_type="section"
      data-settings='{"background_background":"classic","jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"f70193a","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}],"stretch_section":"section-stretched"}'
    >
      <div className="elementor-container elementor-column-gap-default">
        <div
          className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-bbe555c"
          data-id="bbe555c"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-c65de3f elementor-widget elementor-widget-image"
              data-id="c65de3f"
              data-element_type="widget"
              data-widget_type="image.default"
            >
              <div className="elementor-widget-container">
                <img
                  width="512"
                  height="143"
                  src={`${process.env.PUBLIC_URL}/images/footer-logo.png`}
                  className="attachment-full size-full"
                  alt=""
                  loading="lazy"
                  srcSet={`${process.env.PUBLIC_URL}/images/footer-logo.png 512w,
                          ${process.env.PUBLIC_URL}/images/footer-logo-300x84.png 300w`}
                  sizes="(max-width: 512px) 100vw, 512px"
                />
              </div>
            </div>
            <div
              className="elementor-element elementor-element-7e0c3ea elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
              data-id="7e0c3ea"
              data-element_type="widget"
              data-widget_type="icon-list.default"
            >
              <div className="elementor-widget-container">
                <style></style>
                <ul className="elementor-icon-list-items">
                  <li className="elementor-icon-list-item">
                    <a href="tel:09078767326">
                      <span className="elementor-icon-list-icon">
                        <i aria-hidden="true" className="fas fa-phone-alt"></i>
                      </span>
                      <span className="elementor-icon-list-text">09078767326</span>
                    </a>
                  </li>
                  <li className="elementor-icon-list-item">
                    <a href="tel:08097627028">
                      <span className="elementor-icon-list-icon">
                        <i aria-hidden="true" className="fab fa-whatsapp"></i>
                      </span>
                      <span className="elementor-icon-list-text">08097627028</span>
                    </a>
                  </li>
                  <li className="elementor-icon-list-item">
                    <a href="mailto:support@yondacare.com">
                      <span className="elementor-icon-list-icon">
                        <i aria-hidden="true" className="far fa-envelope"></i>
                      </span>
                      <span className="elementor-icon-list-text">
                      support@yondacare.com
                      </span>
                    </a>
                  </li>
                  <li className="elementor-icon-list-item">
                    <a href="https://www.facebook.com/YondaCare-248081627112401">
                      <span className="elementor-icon-list-icon">
                        <i aria-hidden="true" className="fab fa-facebook-f"></i>
                      </span>
                      <span className="elementor-icon-list-text">Yondacare</span>
                    </a>
                  </li>
                  <li className="elementor-icon-list-item">
                    <a href="https://www.instagram.com/YondaCard/">
                      <span className="elementor-icon-list-icon">
                        <i aria-hidden="true" className="fab fa-instagram"></i>
                      </span>
                      <span className="elementor-icon-list-text">Yondacare</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-a6c7e39"
          data-id="a6c7e39"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-04d46ff elementor-widget elementor-widget-heading"
              data-id="04d46ff"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title elementor-size-default">
                  WHY YONDACARE?
                </h2>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-9e099f0 elementor-widget elementor-widget-text-editor"
              data-id="9e099f0"
              data-element_type="widget"
              data-widget_type="text-editor.default"
            >
              <div className="elementor-widget-container">
                <p>
                  <span style={{ fontWeight: 400 }}>At</span>
                  <span style={{ fontWeight: 400 }}> YondaCare </span>
                  <span style={{ fontWeight: 400 }}>
                    we believe every mother and child, teenager, adult and
                    senior citizen should enjoy hassle-free, affordable health
                    care services at their disposal especially at times when an
                    health emergency or an accident occurs.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-08e4b02"
          data-id="08e4b02"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-d4d4bf7 elementor-widget elementor-widget-heading"
              data-id="d4d4bf7"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title elementor-size-default">
                  RESOURCES
                </h2>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-d385d53 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
              data-id="d385d53"
              data-element_type="widget"
              data-widget_type="icon-list.default"
            >
              <div className="elementor-widget-container">
                <ul className="elementor-icon-list-items">
                  <li className="elementor-icon-list-item">
                    <a href="/hospitals">
                      <span className="elementor-icon-list-text">
                        List of Hospitals
                      </span>
                    </a>
                  </li>
                  <li className="elementor-icon-list-item">
                    <Link to="/benefits">
                      <span className="elementor-icon-list-text">
                        Benefits List
                      </span>
                    </Link>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-dacc7ce"
          data-id="dacc7ce"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-516f15f elementor-widget elementor-widget-heading"
              data-id="516f15f"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title elementor-size-default">
                  COMPANY
                </h2>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-d2dbe71 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
              data-id="d2dbe71"
              data-element_type="widget"
              data-widget_type="icon-list.default"
            >
              <div className="elementor-widget-container">
                <ul className="elementor-icon-list-items">
                  <li className="elementor-icon-list-item">
                    <Link to="/about">
                      <span className="elementor-icon-list-text">About</span>
                    </Link>
                  </li>
                  <li className="elementor-icon-list-item">
                    <Link to="/terms-of-use">
                      <span className="elementor-icon-list-text">Terms of use</span>
                    </Link>
                  </li>
                  <li className="elementor-icon-list-item">
                    <Link to="/privacy-policy">
                      <span className="elementor-icon-list-text">
                        Privacy Policy
                      </span>
                    </Link>
                  </li>
                  <li className="elementor-icon-list-item">
                    <a href="/faqs">
                      <span className="elementor-icon-list-text">
                       FAQs
                      </span>
                    </a>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Footer;
