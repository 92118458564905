import React from 'react';
import ElementorContainer from '../components/ElementorContainer';
// import GetStartedForm from '../components/getStarted';
import Layout from '../components/Layout';
import UnderMaintenance from '../components/under-maintenance/UnderMaintenance';

const GetStarted = () => {
    return (
        <Layout title="Get started">
            <ElementorContainer id="1008">
                {/* TODO: Restore when insurance is live again. */}
                {/* <GetStartedForm/> */}
                <UnderMaintenance />
            </ElementorContainer>
        </Layout>
    )
}
export default GetStarted;