
import { toast } from "react-toastify"
export const variables = {
    API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
    PAYSTACK_KEY: process.env.REACT_APP_PAYSTACK_KEY,
    SUB_AMOUNT: Number(process.env.REACT_APP_SUB_AMOUNT),
    HEALTH_PLAN: process.env.REACT_APP_HEALTH_PLAN,
  };
export const successfulPayment = async (response) => {
    toast.loading('Validating Payment....');
    const a = localStorage.getItem("keys") || "";
    let b = {};
    if (a) {
      b = JSON.parse(a);
    }
    const { ids, updateKey } = b;
    await promiseWrapper(
      fetch(`${variables.API_BASE_URL}/forms/retail/${ids}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          updateKey: updateKey || "",
          uniqueIdentifier: response.reference,
        }),
      })
    );
    const [err, resp] = await promiseWrapper(
      fetch(
        `${variables.API_BASE_URL}/yondaCare/processpayment/${response.reference}`,
        {
          method: "GET",
        }
      )
    );
    toast.dismiss();
    localStorage.clear();
        
    if (err) {
      toast.error(`Payment process error: ${err.message}`);
      toast.info(
        "Not to worry! Further instructions would be sent to your email"
      );
    } else {
      toast.success("Successful Payment");
      const proto = window.location.protocol;
      const host = window.location.host;
      window.location = `${proto}//${host}/idcard/${resp.response.user.userID}`;
    }
  };
  
export const callback = (response) => {
    if (response.status === "success") {
      successfulPayment(response);
    } else {
      toast.error(`There was an issue with your payment: ${response.message}`);
    }
  };
export const getReference = () => {
    let text = "";
    let sub = "";
    let possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-.=";
  
    for (let i = 0; i < 6; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    for (let i = 0; i < 6; i++) {
      sub += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return `YONDA-${text}-INS-Image-${sub}`;
  };
export const promiseWrapper = (promise) => {
    return new Promise((resolve) => {
      promise
        .then(async (resp) => {
          if (resp.ok) return resp.json();
          throw await resp.json();
        })
        .then((resp) => {
          resolve([null, resp]);
        })
        .catch((err) => {
          resolve([err, null]);
        });
    });
  };
export const paystackPay = (amount, user, formId, setLoading) => {
    const ref = getReference();
    const paymentData = {
      amount: amount * 100,
      currency: "NGN",
      email: user.email,
      key: variables.PAYSTACK_KEY,
      label: "Yonda Care Health Insurance",
      customerMobileNumber: user.phoneNumber,
      customerFullName: `${user.lastName} ${user.firstName}`,
      metadata: {
        custom_fields: [
          {
            display_name: "app",
            variable_name: "app",
            value: "yondacarehmoinsuranceplatform",
          },
          {
            display_name: "app_data",
            variable_name: "app_data",
            value: JSON.stringify({
              ...user,
              reference: `YNC-INS-${ref}`,
              phoneNumber: convertPhoneToISO(user.phoneNumber, "234"),
            }),
          },
          {
            display_name: "type",
            variable_name: "type",
            value: "retail-form",
          },
          {
            display_name: "typeID",
            variable_name: "typeID",
            value: formId,
          },
        ],
      },
      ref: `YNC-INS-${ref}`,
      onClose: close,
      callback,
    };
    window.PaystackPop.setup(paymentData).openIframe();
  };
  export const convertPhoneToISO = (number, countryCode) => {
    if (number.substring(0, 4) === `+${countryCode}`) {
      return number;
    } else if (number.substring(0, 3) === countryCode) {
      return `+${number}`;
    } else if (number.charAt(0) === "0") {
      return `+${countryCode}${number.slice(1)}`;
    } else {
      return null;
    }
  };
  export const close = (response) => {
    if (response && response.status && response.status === "success") {
      toast.success("Successful Update");
      //setLoading(false);
    }
  };