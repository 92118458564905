const AboutHero = () => {
    return (
        <section
                className="elementor-section elementor-top-section elementor-element elementor-element-d31aa98 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
                data-id="d31aa98"
                data-element_type="section"
                data-settings='{"jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"55989cd","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}],"background_background":"classic"}'
                style={{backgroundImage: "url('images/about.webp')"}}
              >
                <div className="elementor-background-overlay"></div>
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a16a0aa"
                    data-id="a16a0aa"
                    data-element_type="column"
                  >
                    <div
                      className="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        className="elementor-element elementor-element-8c9d049 elementor-widget elementor-widget-heading"
                        data-id="8c9d049"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <style>
                           
                          </style>
                          <h2
                            className="elementor-heading-title elementor-size-default"
                          >
                            About Us
                          </h2>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-dc6ef78 elementor-widget elementor-widget-text-editor"
                        data-id="dc6ef78"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div className="elementor-widget-container">
                          <style>
                            
                          </style>
                          <p>
                            We deliver hassle-free, affordable, easily
                            accessible, quality health insurance that empowers
                            our customers to achieve the best quality of life
                          </p>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-27a642a elementor-widget elementor-widget-button"
                        data-id="27a642a"
                        data-element_type="widget"
                        data-widget_type="button.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-button-wrapper">
                            <a
                              href="/get-started"
                              className="elementor-button-link elementor-button elementor-size-sm"
                              role="button"
                            >
                              <span className="elementor-button-content-wrapper">
                                <span className="elementor-button-text"
                                  >GET STARTED</span
                                >
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3f49538 elementor-hidden-mobile"
                    data-id="3f49538"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap"></div>
                  </div>
                </div>
              </section>
    )
}
export default AboutHero;