import RubberBand from 'react-reveal/RubberBand';

const Values = () => {
    return (
        <section
        className="elementor-section elementor-top-section elementor-element elementor-element-3e389e5 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="3e389e5"
        data-element_type="section"
        data-settings='{"jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"27d51da","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}],"background_background":"gradient","shape_divider_bottom":"curve","shape_divider_bottom_negative":"yes"}'
      >
        <div
          className="elementor-shape elementor-shape-bottom"
          data-negative="true"
        >
          <svg
            xmlns="//www.w3.org/2000/svg"
            viewBox="0 0 1000 100"
            preserveAspectRatio="none"
          >
            <path
              className="elementor-shape-fill"
              d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"
            ></path>
          </svg>
        </div>
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-843a152"
            data-id="843a152"
            data-element_type="column"
          >
            <div
              className="elementor-widget-wrap elementor-element-populated"
            >
              <div
                className="elementor-element elementor-element-2fa4431 elementor-widget elementor-widget-heading"
                data-id="2fa4431"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h2
                    className="elementor-heading-title elementor-size-default"
                  >
                    our value proposition
                  </h2>
                </div>
              </div>
              <section
                className="elementor-section elementor-inner-section elementor-element elementor-element-ecbdd3b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="ecbdd3b"
                data-element_type="section"
                data-settings='{"jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"bc218ca","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}]}'
              >
                <div
                  className="elementor-container elementor-column-gap-default"
                >
                  <RubberBand>
                  <div
                    className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-831e541"
                    data-id="831e541"
                    data-element_type="column"
                  >
                    <div
                      className="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        className="elementor-element elementor-element-7715173 elementor-widget elementor-widget-image"
                        data-id="7715173"
                        data-element_type="widget"
                        data-widget_type="image.default"
                      >
                        <div className="elementor-widget-container">
                          <img
                            width="114"
                            height="112"
                            src="images/single.webp"
                            className="attachment-full size-full"
                            alt=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-2465086 elementor-widget elementor-widget-heading"
                        data-id="2465086"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h2
                            className="elementor-heading-title elementor-size-default"
                          >
                            Single Plan
                          </h2>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-26ccc0d elementor-widget elementor-widget-text-editor"
                        data-id="26ccc0d"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div className="elementor-widget-container">
                          <p>
                            <span
                              style={{
                                fontFamily: "Nunito, sans-serif",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400
                            }}
                              >All inclusive basic healthcare plan</span
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  </RubberBand>
                  <RubberBand>
                  <div
                    className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-dea9cd8"
                    data-id="dea9cd8"
                    data-element_type="column"
                  >
                    <div
                      className="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        className="elementor-element elementor-element-414e6f8 elementor-widget elementor-widget-image"
                        data-id="414e6f8"
                        data-element_type="widget"
                        data-widget_type="image.default"
                      >
                        <div className="elementor-widget-container">
                          <img
                            width="114"
                            height="112"
                            src="images/pocket.webp"
                            className="attachment-full size-full"
                            alt=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-d33a989 elementor-widget elementor-widget-heading"
                        data-id="d33a989"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h2
                            className="elementor-heading-title elementor-size-default"
                          >
                            Pocket-Friendly
                          </h2>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-5e6b279 elementor-widget elementor-widget-text-editor"
                        data-id="5e6b279"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div className="elementor-widget-container">
                          <p>
                            Affordable to all at only  
                            <b> N1500 </b>monthly
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  </RubberBand>
                  <RubberBand>
                  <div
                    className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-7ce1e44"
                    data-id="7ce1e44"
                    data-element_type="column"
                  >
                    <div
                      className="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        className="elementor-element elementor-element-93aed3a elementor-widget elementor-widget-image"
                        data-id="93aed3a"
                        data-element_type="widget"
                        data-widget_type="image.default"
                      >
                        <div className="elementor-widget-container">
                          <img
                            width="113"
                            height="113"
                            src="images/hassle.webp"
                            className="attachment-full size-full"
                            alt=""
                            loading="lazy"
                          />
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-8d33b38 elementor-widget elementor-widget-heading"
                        data-id="8d33b38"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h2
                            className="elementor-heading-title elementor-size-default"
                          >
                            Hassle-Free Service
                          </h2>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-27f0419 elementor-widget elementor-widget-text-editor"
                        data-id="27f0419"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div className="elementor-widget-container">
                          Easy sign-up and virtual card registration
                          process, giving personalized access to 750+
                          quality hospitals nationwide
                        </div>
                      </div>
                    </div>
                  </div>
                  </RubberBand>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    )
}
export default Values;