import React from 'react';
import Carousel from '../components/Carousel';
import ElementorContainer from '../components/ElementorContainer';
import Presence from '../components/Presence';
import Value from '../components/Value';
import WhyYonda from '../components/WhyYonda';
import Layout from '../components/Layout';
import Fade from 'react-reveal/Fade';

const LandingPage = () => {
    return (
        <Layout title="Yondacare">
            <ElementorContainer id="10">
                    <Carousel/>
                    <Fade bottom>
                        <WhyYonda/>
                    </Fade>
                    <Fade bottom>
                    <Value/>
                    </Fade>
                    <Fade bottom>
                    <Presence/>
                    </Fade>
                </ElementorContainer>
        </Layout>
    )
}
export default LandingPage;