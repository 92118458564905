/* eslint-disable */
const Plans = () => {
    return (
        <section
                className="elementor-section elementor-top-section elementor-element elementor-element-703d053 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="703d053"
                data-element_type="section"
                data-settings='{"jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"24be2de","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}],"background_background":"classic"}'
              >
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-907f6a3"
                    data-id="907f6a3"
                    data-element_type="column"
                  >
                    <div
                      className="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        className="elementor-element elementor-element-585f7e2 elementor-widget elementor-widget-heading"
                        data-id="585f7e2"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h2
                            className="elementor-heading-title elementor-size-default"
                          >
                            Our Health Insurance Plan
                          </h2>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-cc66f51 elementor-widget elementor-widget-text-editor"
                        data-id="cc66f51"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div className="elementor-widget-container">
                          A single offer that covers all your emergency and
                          hospital bills
                        </div>
                      </div>
                      <section
                        className="elementor-section elementor-inner-section elementor-element elementor-element-037cb92 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="037cb92"
                        data-element_type="section"
                        data-settings='{"jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"4d28cf0","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}]}'
                      >
                        <div
                          className="elementor-container elementor-column-gap-default"
                        >
                          <div
                            className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-cccd3e0"
                            data-id="cccd3e0"
                            data-element_type="column"
                          >
                            <div
                              className="elementor-widget-wrap elementor-element-populated"
                            >
                              <div
                                className="elementor-element elementor-element-eeb364a elementor-widget elementor-widget-jet-pricing-table"
                                data-id="eeb364a"
                                data-element_type="widget"
                                data-widget_type="jet-pricing-table.default"
                              >
                                <div className="elementor-widget-container">
                                  <div
                                    className="elementor-jet-pricing-table jet-elements"
                                  >
                                    <div className="pricing-table h-440">
                                      <div className="pricing-table__heading">
                                        <h2 className="pricing-table__title">
                                          Emergency
                                        </h2>
                                      </div>
                                      <div className="pricing-table__price">
                                        <span className="pricing-table__price-val"
                                          >50000</span
                                        >
                                        <p className="pricing-table__price-desc">
                                          Resuscitative care for accident and
                                          emergency cases needed to stabilize
                                          patient
                                        </p>
                                      </div>
                                      <div className="pricing-table__features">
                                        <div
                                          className="pricing-table__fold-mask pricing-table-unfold-state"
                                        >
                                          <div
                                            className="pricing-feature pricing-feature-10f592e item-included no-padding"
                                          >
                                            <div className="pricing-feature__inner">
                                              <span
                                                className="item-bullet jet-elements-icon"
                                                ><i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i></span
                                              ><span
                                                className="pricing-feature__text"
                                                >SURGERIES COVERED UP TO ₦50,000</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="pricing-table__action">
                                        <a
                                          className="elementor-button elementor-size-md pricing-table-button button-auto-size"
                                          href="#"
                                        ></a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-9422454"
                            data-id="9422454"
                            data-element_type="column"
                          >
                            <div
                              className="elementor-widget-wrap elementor-element-populated"
                            >
                              <div
                                className="elementor-element elementor-element-0b70997 elementor-widget elementor-widget-jet-pricing-table"
                                data-id="0b70997"
                                data-element_type="widget"
                                data-widget_type="jet-pricing-table.default"
                              >
                                <div className="elementor-widget-container">
                                  <div
                                    className="elementor-jet-pricing-table jet-elements"
                                  >
                                    <div className="pricing-table h-440">
                                      <div className="pricing-table__heading">
                                        <h2 className="pricing-table__title">
                                          Immunization
                                        </h2>
                                      </div>
                                      <div className="pricing-table__price">
                                        <span
                                          className="pricing-table__price-prefix"
                                          >vdf</span
                                        >
                                        <p className="pricing-table__price-desc">
                                          To give you and your loved ones
                                          protection from common diseases
                                        </p>
                                      </div>
                                      <div className="pricing-table__features">
                                        <div
                                          className="pricing-table__fold-mask pricing-table-unfold-state"
                                        >
                                          <div
                                            className="pricing-feature pricing-feature-f4c4ba6 item-included"
                                          >
                                            <div className="pricing-feature__inner">
                                              <span
                                                className="item-bullet jet-elements-icon"
                                                ><i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i></span
                                              ><span
                                                className="pricing-feature__text"
                                                >(NPI) for 0 - 5yrs</span
                                              >
                                            </div>
                                          </div>
                                          <div
                                            className="pricing-feature pricing-feature-10f592e item-included"
                                          >
                                            <div className="pricing-feature__inner">
                                              <span
                                                className="item-bullet jet-elements-icon"
                                                ><i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i></span
                                              ><span
                                                className="pricing-feature__text"
                                                >OPV/IPV</span
                                              >
                                            </div>
                                          </div>
                                          <div
                                            className="pricing-feature pricing-feature-e6aa442 item-included"
                                          >
                                            <div className="pricing-feature__inner">
                                              <span
                                                className="item-bullet jet-elements-icon"
                                                ><i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i></span
                                              ><span
                                                className="pricing-feature__text"
                                                >Pentavalent</span
                                              >
                                            </div>
                                          </div>
                                          <div
                                            className="pricing-feature pricing-feature-4dcb650 item-included"
                                          >
                                            <div className="pricing-feature__inner">
                                              <span
                                                className="item-bullet jet-elements-icon"
                                                ><i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i></span
                                              ><span
                                                className="pricing-feature__text"
                                                >Hepatitis B</span
                                              >
                                            </div>
                                          </div>
                                          <div
                                            className="pricing-feature pricing-feature-60cc251 item-included"
                                          >
                                            <div className="pricing-feature__inner">
                                              <span
                                                className="item-bullet jet-elements-icon"
                                                ><i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i></span
                                              ><span
                                                className="pricing-feature__text"
                                                >DPT</span
                                              >
                                            </div>
                                          </div>
                                          <div
                                            className="pricing-feature pricing-feature-c8abf2c item-included"
                                          >
                                            <div className="pricing-feature__inner">
                                              <span
                                                className="item-bullet jet-elements-icon"
                                                ><i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i></span
                                              ><span
                                                className="pricing-feature__text"
                                                >Vitamin A</span
                                              >
                                            </div>
                                          </div>
                                          <div
                                            className="pricing-feature pricing-feature-cbf3b57 item-included"
                                          >
                                            <div className="pricing-feature__inner">
                                              <span
                                                className="item-bullet jet-elements-icon"
                                                ><i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i></span
                                              ><span
                                                className="pricing-feature__text"
                                                >Measles</span
                                              >
                                            </div>
                                          </div>
                                          <div
                                            className="pricing-feature pricing-feature-7c9e3a7 item-included"
                                          >
                                            <div className="pricing-feature__inner">
                                              <span
                                                className="item-bullet jet-elements-icon"
                                                ><i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i></span
                                              ><span
                                                className="pricing-feature__text"
                                                >Yellow Fever</span
                                              >
                                            </div>
                                          </div>
                                          <div
                                            className="pricing-feature pricing-feature-5297885 item-included"
                                          >
                                            <div className="pricing-feature__inner">
                                              <span
                                                className="item-bullet jet-elements-icon"
                                                ><i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i></span
                                              ><span
                                                className="pricing-feature__text"
                                                >BCG</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="pricing-table__action">
                                        <a
                                          className="elementor-button elementor-size-md pricing-table-button button-auto-size"
                                          href="#"
                                        ></a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-d7666c0"
                            data-id="d7666c0"
                            data-element_type="column"
                          >
                            <div
                              className="elementor-widget-wrap elementor-element-populated"
                            >
                              <div
                                className="elementor-element elementor-element-16dca21 elementor-widget elementor-widget-jet-pricing-table"
                                data-id="16dca21"
                                data-element_type="widget"
                                data-widget_type="jet-pricing-table.default"
                              >
                                <div className="elementor-widget-container">
                                  <div
                                    className="elementor-jet-pricing-table jet-elements"
                                  >
                                    <div className="pricing-table h-440">
                                      <div className="pricing-table__heading">
                                        <h2 className="pricing-table__title">
                                          Treatments
                                        </h2>
                                      </div>
                                      <div className="pricing-table__price">
                                        <span className="pricing-table__price-val"
                                          >dfg</span
                                        >
                                        <p className="pricing-table__price-desc">
                                          All inclusive diagnostics and
                                          treatment (Up to a limit of N100,000)
                                          covering In/Out patient admissions
                                        </p>
                                      </div>
                                      <div className="pricing-table__features">
                                        <div
                                          className="pricing-table__fold-mask pricing-table-unfold-state"
                                        >
                                          <div
                                            className="pricing-feature pricing-feature-f4c4ba6 item-included"
                                          >
                                            <div className="pricing-feature__inner">
                                              <span
                                                className="item-bullet jet-elements-icon"
                                                ><i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i></span
                                              ><span
                                                className="pricing-feature__text"
                                                >General and Specialist</span
                                              >
                                            </div>
                                          </div>
                                          <div
                                            className="pricing-feature pricing-feature-10f592e item-included"
                                          >
                                            <div className="pricing-feature__inner">
                                              <span
                                                className="item-bullet jet-elements-icon"
                                                ><i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i></span
                                              ><span
                                                className="pricing-feature__text"
                                                >Consultation
                                              </span>
                                            </div>
                                          </div>
                                          <div
                                            className="pricing-feature pricing-feature-e6aa442 item-included"
                                          >
                                            <div className="pricing-feature__inner">
                                              <span
                                                className="item-bullet jet-elements-icon"
                                                ><i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i></span
                                              ><span
                                                className="pricing-feature__text"
                                                >Prescribed Medications</span
                                              >
                                            </div>
                                          </div>
                                          <div
                                            className="pricing-feature pricing-feature-4dcb650 item-included"
                                          >
                                            <div className="pricing-feature__inner">
                                              <span
                                                className="item-bullet jet-elements-icon"
                                                ><i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i></span
                                              ><span
                                                className="pricing-feature__text"
                                                >Out Patient Care</span
                                              >
                                            </div>
                                          </div>
                                          <div
                                            className="pricing-feature pricing-feature-74962a8 item-included"
                                          >
                                            <div className="pricing-feature__inner">
                                              <span
                                                className="item-bullet jet-elements-icon"
                                                ><i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i></span
                                              ><span
                                                className="pricing-feature__text"
                                                >Urinary Tract Infection</span
                                              >
                                            </div>
                                          </div>
                                          <div
                                            className="pricing-feature pricing-feature-0c2731a item-included"
                                          >
                                            <div className="pricing-feature__inner">
                                              <span
                                                className="item-bullet jet-elements-icon"
                                                ><i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i></span
                                              ><span
                                                className="pricing-feature__text"
                                                >Typhoid</span
                                              >
                                            </div>
                                          </div>
                                          <div
                                            className="pricing-feature pricing-feature-f9b5aac item-included"
                                          >
                                            <div className="pricing-feature__inner">
                                              <span
                                                className="item-bullet jet-elements-icon"
                                                ><i
                                                  aria-hidden="true"
                                                  className="fas fa-check"
                                                ></i></span
                                              ><span
                                                className="pricing-feature__text"
                                                >Mild Bronchial Asthma</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="pricing-table__action">
                                        <a
                                          className="elementor-button elementor-size-md pricing-table-button button-auto-size"
                                          href="#"
                                        ></a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </section>
    )
}
export default Plans;