import { Link } from "react-router-dom"
export function Header(){
    return (
        <header
          id="site-header"
          className="clr"
          data-height="74"
          itemScope="itemScope"
          itemType="https://schema.org/WPHeader"
          role="banner"
        >
          <div
            data-elementor-type="header"
            data-elementor-id="19"
            className="elementor elementor-19 elementor-location-header"
            data-elementor-settings="[]"
          >
            <div className="elementor-section-wrap">
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-9e58f7f elementor-section-content-middle she-header-yes elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="9e58f7f"
                data-element_type="section"
                data-settings='{"transparent":"yes","jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"23e7624","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}],"background_show":"yes","background":"#EBEBEBFC","transparent_on":["desktop","tablet","mobile"],"scroll_distance":{"unit":"px","size":60,"sizes":[]},"scroll_distance_tablet":{"unit":"px","size":"","sizes":[]},"scroll_distance_mobile":{"unit":"px","size":"","sizes":[]}}'
              >
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-ac49149"
                    data-id="ac49149"
                    data-element_type="column"
                  >
                    <div
                      className="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        className="elementor-element elementor-element-a07a002 elementor-widget elementor-widget-image"
                        data-id="a07a002"
                        data-element_type="widget"
                        data-widget_type="image.default"
                      >
                        <div className="elementor-widget-container">
                          {/* <style>
                            
                          </style> */}
                          <Link to="/">
                            <img
                              width="561"
                              height="156"
                              src={`${process.env.PUBLIC_URL}/images/yondacareblack.png`}
                              className="attachment-full size-full"
                              alt=""
                              loading="lazy"
                              srcSet={`
                              ${process.env.PUBLIC_URL}/images/yondacareblack.png        561w,
                              ${process.env.PUBLIC_URL}/images/yondacareblack-300x83.png 300w
                              `}
                              sizes="(max-width: 561px) 100vw, 561px"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-8d54d77"
                    data-id="8d54d77"
                    data-element_type="column"
                  >
                    <div
                      className="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        className="elementor-element elementor-element-1261fe0 elementor-nav-menu__align-right elementor-nav-menu--dropdown-mobile elementor-nav-menu--stretch elementor-nav-menu__text-align-aside elementor-nav-menu--toggle elementor-nav-menu--burger elementor-widget elementor-widget-nav-menu"
                        data-id="1261fe0"
                        data-element_type="widget"
                        data-settings='{"full_width":"stretch","layout":"horizontal","submenu_icon":{"value":"<i className=\"fas fa-caret-down\"><\/i>","library":"fa-solid"},"toggle":"burger"}'
                        data-widget_type="nav-menu.default"
                      >
                        <div className="elementor-widget-container">
                          <link
                            rel="stylesheet"
                            href={`${process.env.PUBLIC_URL}/css/widget-nav-menu.min.css`}
                          />
                          <nav
                            migration_allowed="1"
                            migrated="0"
                            role="navigation"
                            className="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-horizontal e--pointer-none"
                          >
                            <ul id="menu-1-1261fe0" className="elementor-nav-menu">
                              <li
                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-10 current_page_item menu-item-18"
                              >
                                <Link
                                  to="/index"
                                  aria-current="page"
                                  className="elementor-item elementor-item-active"
                                  >WHY YONDACARE
                                  </Link>
                              </li>
                              <li
                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17"
                              >
                                <Link to="/benefits" className="elementor-item"
                                  >BENEFITS
                                </Link>
                              </li>
                              <li
                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-16"
                              >
                                <a href="/hospitals" className="elementor-item"
                                  >HOSPITALS
                                  </a>
                              </li>
                            </ul>
                          </nav>
                          <div
                            className="elementor-menu-toggle"
                            role="button"
                            tabIndex="0"
                            aria-label="Menu Toggle"
                            aria-expanded="false"
                          >
                            <i
                              aria-hidden="true"
                              role="presentation"
                              className="elementor-menu-toggle__icon--open eicon-menu-bar"
                            ></i
                            ><i
                              aria-hidden="true"
                              role="presentation"
                              className="elementor-menu-toggle__icon--close eicon-close"
                            ></i>
                            <span className="elementor-screen-only">Menu</span>
                          </div>
                          <nav
                            className="elementor-nav-menu--dropdown elementor-nav-menu__container"
                            role="navigation"
                            aria-hidden="true"
                          >
                            <ul id="menu-2-1261fe0" className="elementor-nav-menu">
                              <li
                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-10 current_page_item menu-item-18"
                              >
                                <Link
                                  to="/index"
                                  aria-current="page"
                                  className="elementor-item elementor-item-active"
                                  tabIndex="-1"
                                  >WHY YONDACARE</Link
                                >
                              </li>
                              <li
                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17"
                              >
                                <Link
                                  to="/benefits"
                                  className="elementor-item"
                                  tabIndex="-1"
                                  >BENEFITS
                                </Link>
                              </li>
                              <li
                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-16"
                              >
                                <a
                                  href="/hospitals"
                                  className="elementor-item"
                                  tabIndex="-1"
                                  >HOSPITALS
                                  </a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-f02e9fa elementor-hidden-mobile"
                    data-id="f02e9fa"
                    data-element_type="column"
                  >
                    <div
                      className="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        className="elementor-element elementor-element-49be9a1 elementor-widget elementor-widget-button"
                        data-id="49be9a1"
                        data-element_type="widget"
                        data-widget_type="button.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-button-wrapper">
                            <Link
                              to="/get-started"
                              className="elementor-button-link elementor-button elementor-size-sm"
                              role="button"
                            >
                              <span className="elementor-button-content-wrapper">
                                <span className="elementor-button-text">
                                  GET STARTED</span
                                >
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </header>
    )   
}   