const ElementorContainer = ({type, id, children, classes}) => {
    return (
        <div
            data-elementor-type={type ? type : "wp-page"}
            data-elementor-id={id}
            className={`elementor elementor-${id} ${classes}`}
            data-elementor-settings="[]"
          >
            <div className="elementor-section-wrap">
                {children}
            </div>
        </div>
    )
}
export default ElementorContainer