/* eslint-disable jsx-a11y/anchor-is-valid */
import PolicyStatus from "./validate/PolicyStatus";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { paystackPay, variables } from "./Utils";
import { useState } from "react";
import { Fade } from "react-reveal";

const PolicyCard = ({ user }) => {
  let navigate = useNavigate();
  const [months, setMonths] = useState(1);
  const [openRenew, setRenewal] = useState(false);
  const getStatus = (subData) => {
    const { activated, terminated, expireAt } = subData;
    const today = new Date();
    if (activated && !terminated && moment(expireAt).isAfter(moment(today))) {
      return "ACTIVE";
    } else if (!activated && !terminated) {
      return "PENDING";
    } else if (
      activated &&
      !terminated &&
      moment(expireAt).isBefore(moment(today))
    ) {
      return "EXPIRED";
    } else if (terminated) {
      return "CANCELLED";
    }
  };
  const ViewPolicy = () => {
    navigate(`/idcard/${user?.subscription?.userId}`, { replace: false });
  };
  const renewPolicy = async () => {
    let userdetails = { ...user?.form, ...user.subscription };
    localStorage.setItem(
      "keys",
      JSON.stringify({
        ids: userdetails.formId,
        updateKey: userdetails.updateKey,
      })
    );
    setRenewal(false);
    //const user = { ...form, formId: user?.form?.id}
    return paystackPay(
      months * variables.SUB_AMOUNT,
      userdetails,
      userdetails.formId
    );
  };
  return (
    user && (
      <Fade>
        <div className="user-policy-card">
          <div className="card-section user">
            <h4 className="user-name">{`${user?.form?.firstName} ${user?.form?.lastName} `}</h4>
            <h6>{user?.form?.email} </h6>
            <PolicyStatus
              status={user?.subscription.activated}
              text={getStatus(user?.subscription)}
            />
            <p className="amount">&#8358;{user?.form?.amount}</p>
          </div>
          <div className="card-section">
            <div className="details">
              <h6>Policy Number</h6>
              <h5>{user?.form?.policyNumber || "PENDING"}</h5>
            </div>
            <div className="details">
              <h6>Policy Expiry Date</h6>
              <h5>{moment(user?.subscription?.expireAt).format("DD/MM/YY")}</h5>
              <button onClick={ViewPolicy}>View Policy Card</button>
            </div>
          </div>
          <div className="card-section">
            <div className="details">
              <h6>Sex</h6>
              <h5>{user?.form?.sex}</h5>
            </div>
            <div className="details">
              <h6>Phone Number</h6>
              <h5>{user?.form?.phoneNumber}</h5>
              {openRenew ? (
                <Fade bottom>
                  <div>
                    <label className="gfield_label" for="input_2_19">
                      Number of months
                    </label>
                    <div>
                      <select
                        name="months"
                        className="ginput_total py-2 select-months"
                        id="input_2_19"
                        onChange={(e) => setMonths(Number(e.target.value))}
                        defaultValue={months}
                      >
                        <option
                          name="1-months"
                          className="ginput_total"
                          value="1"
                        >
                          1
                        </option>
                        <option
                          name="2-months"
                          className="ginput_total"
                          value="2"
                        >
                          2
                        </option>
                        <option
                          name="3-months"
                          className="ginput_total"
                          value="3"
                        >
                          3
                        </option>
                        <option
                          name="4-months"
                          className="ginput_total"
                          value="4"
                        >
                          4
                        </option>
                        <option
                          name="5-months"
                          className="ginput_total"
                          value="5"
                        >
                          5
                        </option>
                        <option
                          name="6-months"
                          className="ginput_total"
                          value="6"
                        >
                          6
                        </option>
                        <option
                          name="7-months"
                          className="ginput_total"
                          value="7"
                        >
                          7
                        </option>
                        <option
                          name="8-months"
                          className="ginput_total"
                          value="8"
                        >
                          8
                        </option>
                        <option
                          name="9-months"
                          className="ginput_total"
                          value="9"
                        >
                          9
                        </option>
                        <option
                          name="10-months"
                          className="ginput_total"
                          value="10"
                        >
                          10
                        </option>
                        <option
                          name="11-months"
                          className="ginput_total"
                          value="11"
                        >
                          11
                        </option>
                        <option
                          name="12-months"
                          className="ginput_total"
                          value="12"
                        >
                          12
                        </option>
                      </select>
                      <button onClick={renewPolicy}>Proceed</button>
                      <label className="total-details">
                        &#8358; {variables.SUB_AMOUNT * months} for {months}{" "}
                        month
                        {months > 1 && "s"}
                        <a
                          className="cancel-renewal"
                          onClick={() => setRenewal(false)}
                        >
                          {" "}
                          &#215; Cancel Renewal
                        </a>
                      </label>
                    </div>
                  </div>
                </Fade>
              ) : (
                <button onClick={() => setRenewal(true)}>
                  Renew Subscription
                </button>
              )}
            </div>
          </div>
        </div>
      </Fade>
    )
  );
};
export default PolicyCard;
