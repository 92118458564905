import React from 'react';
import ElementorContainer from '../components/ElementorContainer';
import Layout from '../components/Layout';
import HospitalHero from '../components/hospitals/hero';
import HospitalPresence from '../components/hospitals/presence';
import HospitalList from '../components/hospitals/hospitals';
import HospitalMap from '../components/hospitals/hospitalMap';

const Hospitals = () => {
    return (
        <Layout title="Yondacare Hospitals">
                <ElementorContainer id="14">
                    <HospitalHero/>
                    <HospitalPresence/>
                    <HospitalList/>
                    <HospitalMap/>
                </ElementorContainer>
        </Layout>
    )
}
export default Hospitals;