const TermsList = () => {
    return (
        <section
        className="elementor-section elementor-top-section elementor-element elementor-element-be1f595 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="be1f595"
        data-element_type="section"
        data-settings='{"jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"0cbb4aa","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}],"background_background":"classic"}'
      >
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a922acf"
            data-id="a922acf"
            data-element_type="column"
          >
            <div
              className="elementor-widget-wrap elementor-element-populated"
            >
              <div
                className="elementor-element elementor-element-767ad19 elementor-widget elementor-widget-text-editor"
                data-id="767ad19"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div className="elementor-widget-container">
                  <style>
                   
                  </style>
                  <div className="c12">
                    <h2 id="h.5nnd21cxufzi" className="c8">
                      <span className="c3">1. Introduction</span>
                    </h2>
                    <p className="c4">
                      <span className="c5"
                        >This document (together with any documents
                        mentioned herein) sets out the terms and
                        conditions governing the use of this website and
                        the purchase of our health insurance plan
                        (“</span
                      ><span className="c5 c6">Plan</span
                      ><span className="c5"
                        >”) through this website (hereinafter, the
                        “</span
                      ><span className="c5 c6">Terms</span
                      ><span className="c5"
                        >“). Please read through these Terms, and our </span
                      ><span className="c9"
                        ><a
                          className="c10"
                          href="https://www.google.com/url?q=https://dev-yonda.ajocard.com/privacy&amp;sa=D&amp;source=editors&amp;ust=1642972618271309&amp;usg=AOvVaw0HwEmveuKKsv0mpAOrnVJ-"
                          >Privacy Policy</a
                        ></span
                      ><span className="c0"
                        >&nbsp;(together the “Data Protection Policies”)
                        prior to using this website.</span
                      >
                    </p>
                    <p className="c4">
                      <span className="c5"
                        >By using this website or placing an order for
                        any Plan through it, you hereby give your
                        consent to and agree to be bound by these Terms
                        and our Data Protection Policies. If you do not
                        agree to all of the Terms and the Data
                        Protection Policies, </span
                      ><span className="c5 c6">do not use this website</span
                      ><span className="c0">.</span>
                    </p>
                    <p className="c4">
                      <span className="c5"
                        >In this document “you” or “your” refers to the
                        user of this website and “we” refers to
                        YondaCare Limited. “website” means </span
                      ><span className="c9"
                        ><a
                          className="c10"
                          href="https://www.google.com/url?q=https://dev-yonda.ajocard.com/&amp;sa=D&amp;source=editors&amp;ust=1642972618271861&amp;usg=AOvVaw3iNupJtrlGDEdsxyBZDqmW"
                          >www.yondacare.com</a
                        ></span
                      ><span className="c0"
                        >, any other URL operated by us and our mobile
                        applications.</span
                      >
                    </p>
                    <h2 id="h.xo00frxzbmsm" className="c8">
                      <span className="c3">2. Use of this Website</span>
                    </h2>
                    <p className="c4">
                      <span className="c0"
                        >By using this website and/or by placing any
                        order through it, you undertake:</span
                      >
                    </p>
                    <ul className="c11 lst-kix_ub1fe6no3fgx-0 start">
                      <li className="c1 li-bullet-0">
                        <span className="c0"
                          >To use the website exclusively to make
                          legitimate enquiries or orders</span
                        >
                      </li>
                      <li className="c1 li-bullet-0">
                        <span className="c0"
                          >Not to make any speculative, false or
                          fraudulent enquiries or orders. If we are
                          reasonably of the opinion that such an order
                          has been made, we shall be entitled to cancel
                          the order and inform the relevant
                          authorities.</span
                        >
                      </li>
                      <li className="c1 li-bullet-0">
                        <span className="c0"
                          >To provide correct and accurate contact
                          details to us and acknowledge that we may use
                          these details to contact you in the event that
                          this should prove necessary (see our Data
                          Protection Policies). If you do not give us
                          all of the information that we need, we may
                          not be able to complete your order. By placing
                          an order through this website, you warrant
                          that you are at least 18 years old and are
                          legally capable of entering into binding
                          contracts.</span
                        >
                      </li>
                      <li className="c1 li-bullet-0">
                        <span className="c0"
                          >Not to: use this website in any way that is,
                          or may be, damaging to this website; use this
                          website in any way that impacts user access to
                          this website; use this website contrary to
                          applicable laws and regulations, or in a way
                          that causes, or may cause, harm to the
                          website, or to any person or business entity;
                          engage in any data mining, data harvesting,
                          data extracting or any other similar activity
                          in relation to this website, or while using
                          this website; or use this website to engage in
                          any advertising or marketing.</span
                        >
                      </li>
                    </ul>
                    <h2 id="h.oy6a55im0dhz" className="c8">
                      <span className="c3">3. Changes</span>
                    </h2>
                    <p className="c4">
                      <span className="c5"
                        >These Terms and the Data Protection Policies
                        may be amended. It is your responsibility to
                        regularly read through them, as the Terms and
                        the Data Protection Policies in force at the
                        time that you use this website or at the time of
                        the formation of the Contract (as defined below)
                        shall be the applicable ones. </span
                      ><span className="c5"
                        >We reserve the right to modify or withdraw this
                        Website at any time without recourse or
                        liability to you.</span
                      ><span className="c0"
                        >&nbsp;However, when such changes are made, we
                        shall notify you accordingly.</span
                      >
                    </p>
                    <h2 id="h.cse121g13kmj" className="c8">
                      <span className="c3">4. Your Details</span>
                    </h2>
                    <p className="c4">
                      <span className="c0"
                        >The information or personal details that you
                        provide us with shall be processed pursuant to
                        the Data Protection Policies. By using this
                        website you are consenting to the processing of
                        such information and details and you represent
                        that the whole information or details you have
                        provided us with are true and accurate. We shall
                        not be responsible for any loss which you may
                        suffer as a result of any false information or
                        details which you have provided to us.</span
                      >
                    </p>
                    <h2 id="h.ab97thx8wok5" className="c8">
                      <span className="c3">5. Purchase of a Plan</span>
                    </h2>
                    <p className="c4">
                      <span className="c0"
                        >The information set out in the Terms and the
                        detail contained on this website do not
                        constitute an offer for sale, but rather an
                        invitation to treat. No contract in respect of
                        any Plan will exist between us and you until
                        your order has been expressly accepted by us,
                        whether or not funds have been deducted from
                        your account. If we do not accept your offer and
                        funds have already been deducted, these will be
                        fully refunded.</span
                      >
                    </p>
                    <p className="c4">
                      <span className="c5"
                        >The contract for the purchase of any Plan
                        between us will only be formed when you complete
                        a purchase and we send you the confirmation of
                        purchase. By purchasing any Plan from this
                        website, you enter into a contract with us on
                        these terms and on the terms contained in the
                        Retail Plan Terms and Conditions (“</span
                      ><span className="c5 c6">the Contract</span
                      ><span className="c0"
                        >”) which can be accessed be found this page and
                        which will be provided to you as a check box
                        before you pay through the payment platform on
                        the website.</span
                      >
                    </p>
                    <p className="c4">
                      <span className="c0"
                        >The Contract will relate only to those Plans
                        whose purchase we have confirmed in the
                        confirmation.</span
                      >
                    </p>
                    <h2 id="h.qilewapa7cyq" className="c8">
                      <span className="c3">6. Price and Payment</span>
                    </h2>
                    <p className="c4">
                      <span className="c0"
                        >The price of any Plan will be as stipulated on
                        our website from time to time, except in cases
                        of obvious error. While we try to ensure that
                        all prices on this website are accurate, errors
                        may occur. If we discover an error in the price
                        of items you have ordered, we will inform you as
                        soon as possible and give you the option of
                        reconfirming the order at the correct price or
                        cancelling it. If we are unable to contact you,
                        the order will be treated as cancelled and if
                        you have already paid for the Plan you will
                        receive a full refund.</span
                      >
                    </p>
                    <p className="c4">
                      <span className="c0"
                        >We are under no obligation to provide the items
                        to you at the incorrect (lower) price (even
                        after we have sent you a confirmation) if there
                        is a pricing error. Prices are liable to change
                        at any time, but (other than as set out above)
                        changes will not affect orders in respect of
                        which we have already sent you a
                        confirmation.</span
                      >
                    </p>
                    <p className="c4">
                      <span className="c0"
                        >You confirm that the debit or credit card used
                        in purchasing the Plan is yours or that you are
                        the legitimate holder and have the authority of
                        the owner to use it. Credit and debit cards are
                        subject to validation checks and authorisation
                        by your card issuer, but if your card issuer
                        fails to authorise payment to us, we will not be
                        liable for any delay and may not be able to form
                        a contract with you.</span
                      >
                    </p>
                    <h2 id="h.6ag49g1nzbwy" className="c8">
                      <span className="c3">7. Indemnity</span>
                    </h2>
                    <p className="c4">
                      <span className="c0"
                        >You agree to indemnify and hold us, our
                        affiliates, officers, directors, agents and/or
                        employees, as the case may be, free from any
                        claim or demand, including reasonable legal
                        fees, related to your breach of these
                        Terms.</span
                      >
                    </p>
                    <h2 id="h.7xzgdkvmnia8" className="c8">
                      <span className="c3"
                        >8. Liability and Disclaimers</span
                      >
                    </h2>
                    <p className="c4">
                      <span className="c0"
                        >This website and the Plans on this site are
                        provided “as is” and without warranties of any
                        kind, whether express or implied. To the fullest
                        extent permissible pursuant to applicable law,
                        we disclaim all warranties, express or implied,
                        including, but not limited to, implied
                        warranties of merchantability and fitness for a
                        particular purpose and non-infringement. We do
                        not represent or warrant that the functions
                        contained in the site will be uninterrupted or
                        error-free, that the defects will be corrected,
                        or that this website or the server that makes
                        the website available are free of viruses or
                        other harmful components. We do not make any
                        warranties or representations regarding the use
                        of the materials in this website in terms of
                        their correctness, accuracy, adequacy,
                        usefulness, timeliness, reliability or
                        otherwise.</span
                      >
                    </p>
                    <p className="c4">
                      <span className="c0"
                        >Neither YondaCare nor its directors, officers,
                        employees, agents, contractors, or licensors
                        shall be liable for any direct or incidental,
                        special or consequential damages under or
                        arising from the Data Protection Policies, the
                        use of this website or any internet site linked
                        to or from this website, whether for breach of
                        contract, tortuous behaviour, negligence, or
                        under any other cause of action, unless such
                        action was caused by the wilful conduct of
                        YondaCare or such other persons mentioned
                        above.</span
                      >
                    </p>
                    <p className="c4">
                      <span className="c0"
                        >Neither YondaCare nor its directors, officers,
                        employees, agents, contractors or licensors
                        shall be liable to you or any other third party
                        for any decision made or action taken by you in
                        YondaCare on the content contained on the
                        website or the content contained within any
                        internet site linked to or from this website.
                        You specifically acknowledge and agree that
                        YondaCare is not liable for any defamatory,
                        offensive, fraudulent, or otherwise illegal
                        conduct of any user. If you are dissatisfied
                        with any content of the website, your sole and
                        exclusive remedy is to discontinue using the
                        website.</span
                      >
                    </p>
                    <p className="c8">
                      <span className="c0"
                        >Due to the open nature of this website and the
                        potential for errors in the storage and
                        transmission of digital information, we do not
                        warrant the accuracy and security of information
                        transmitted to or obtained from this website
                        unless otherwise expressly set out on this
                        website. All item descriptions, information and
                        materials posted on this website are provided
                        “as is” and without warranties express, implied
                        or otherwise however arising.</span
                      >
                    </p>
                    <h2 id="h.i0zg8bbcw910" className="c8">
                      <span className="c3">9. Intellectual Property</span>
                    </h2>
                    <p className="c4">
                      <span className="c0"
                        >You acknowledge and agree that other than
                        content you own, which you may have opted to
                        include on this website, all copyright,
                        trademarks and all other intellectual property
                        rights in all material or content supplied as
                        part of this website shall remain at all times
                        vested in us or our licensors. You may not
                        modify, copy, reproduce, transmit, distribute,
                        publish, create derivative works from, display
                        or otherwise transfer or commercially exploit
                        any of the contents of our website, in whole or
                        in part other than as permitted under these
                        Terms. You are granted a limited license only,
                        subject to the restrictions provided in these
                        Terms, for purposes of viewing the material
                        contained on this website. You are expressly and
                        emphatically restricted from all of the
                        following: publishing any website material in
                        any media; selling, sublicensing and/or
                        otherwise commercializing any website material;
                        publicly performing and/or showing any website
                        material</span
                      >
                    </p>
                    <h2 id="h.20wupavhwras" className="c8">
                      <span className="c3"
                        >10. Viruses, Hacking and other Cyber
                        Crimes</span
                      >
                    </h2>
                    <p className="c4">
                      <span className="c0"
                        >You may not misuse this website by knowingly
                        introducing viruses, Trojans, worms, logic bombs
                        or other material which is malicious or
                        technologically harmful. You will not attempt to
                        have any unauthorized access to this website, to
                        the server which hosts this website or to any
                        other server, computer or database related to
                        our website. You undertake not to attack this
                        website via a denial of service attack or a
                        distributed denial of service attack. By
                        breaching this provision you may commit a
                        criminal offence under the applicable
                        regulations. We will report any such breach to
                        the relevant law enforcement authority and we
                        will cooperate with the appropriate authority to
                        disclose the identity of the hacker. Likewise,
                        in the event of such a breach, your right to use
                        this website will cease immediately. We will use
                        reasonable care and skill to ensure that this
                        website is safe, secure and free from bugs,
                        viruses and other defects. Except to the extent
                        it results from our failure to do so, we accept
                        no liability for any loss or damage resulting
                        from any denial of service attack, virus or any
                        other software or material which is malicious or
                        technologically harmful to your computer,
                        equipment, data or material resulting from the
                        use of this website or from the downloading of
                        the contents thereof or of such contents to
                        which this website redirects.</span
                      >
                    </p>
                    <h2 id="h.reah089ag2r0" className="c8">
                      <span className="c3">11. Links from our Website</span>
                    </h2>
                    <p className="c4">
                      <span className="c0"
                        >We may have links from our website to other
                        third party websites and materials; such links
                        are provided exclusively for information
                        purposes and we do not have any control
                        whatsoever over the contents of such websites or
                        materials. Accordingly, we accept no liability
                        whatsoever for any loss or damage which may
                        arise from the use of such links.</span
                      >
                    </p>
                    <h2 id="h.3sppzfksgxn" className="c8">
                      <span className="c3"
                        >12. Events outside our control</span
                      >
                    </h2>
                    <p className="c4">
                      <span className="c0"
                        >We will not be liable or responsible for any
                        failure to perform, or delay in performance of,
                        any of our obligations under the Contract or
                        these Terms that are caused by events outside
                        our reasonable control. An event outside our
                        control includes any act, event, non-happening,
                        omission or accident beyond our reasonable
                        control and includes in particular (without
                        limitation) the following:</span
                      >
                    </p>
                    <ul className="c11 lst-kix_ho9kn3apmnfp-0 start">
                      <li className="c1 li-bullet-0">
                        <span className="c0"
                          >Strikes, lock-outs or other industrial
                          action</span
                        >
                      </li>
                      <li className="c1 li-bullet-0">
                        <span className="c0"
                          >Civil commotion, riot, invasion, terrorist
                          attack or threat of terrorist attack, war
                          (whether declared or not) or threat or
                          preparation for war.</span
                        >
                      </li>
                      <li className="c1 li-bullet-0">
                        <span className="c0"
                          >Fire, explosion, storm, flood, earthquake,
                          subsidence, epidemic or other natural
                          disaster.</span
                        >
                      </li>
                      <li className="c1 li-bullet-0">
                        <span className="c0"
                          >The acts, decrees, legislation, regulations
                          or restrictions of any government.</span
                        >
                      </li>
                    </ul>
                    <h2 id="h.jtl1nucd0nhq" className="c8">
                      <span className="c3">13. Written Communications</span>
                    </h2>
                    <p className="c4">
                      <span className="c0"
                        >When using our site, you accept that
                        communication with us will be mainly electronic.
                        We will contact you by e-mail or provide you
                        with information by posting notices on our
                        website. For contractual purposes, you agree to
                        this electronic means of communication and you
                        acknowledge that all contracts, notices,
                        information and other communications that we
                        provide to you electronically comply with any
                        legal requirement that such communications be in
                        writing.</span
                      >
                    </p>
                    <h2 id="h.ss6obsv6p1q5" className="c8">
                      <span className="c3">14. Notices</span>
                    </h2>
                    <p className="c4">
                      <span className="c5"
                        >All notices given by you to us should be given
                        to us via email to </span
                      ><span className="c2">hello@yondacare.com</span
                      ><span className="c0"
                        >. Subject to and as otherwise specified in
                        Clause 13, we may give notice to you at either
                        the e-mail or postal address you provide to us
                        when purchasing a Plan. Notice will be deemed
                        received and properly served immediately when
                        posted on our website, 24 hours after an email
                        is sent, or three days after the date of posting
                        of any letter. In proving the service of any
                        notice, it will be sufficient to prove, in the
                        case of a letter, that such letter was properly
                        addressed, stamped and placed in the post and,
                        in the case of an e-mail that such e-mail was
                        sent to the specified e-mail address of the
                        addressee.</span
                      >
                    </p>
                    <h2 id="h.k72bkeken433" className="c8">
                      <span className="c3">15. Waiver</span>
                    </h2>
                    <p className="c4">
                      <span className="c0"
                        >If we fail, at any time during the term of the
                        Contract, to insist upon strict performance of
                        any of your obligations under the Contract or
                        any of these Terms, or if we fail to exercise
                        any of the rights or remedies to which we are
                        entitled under the Contract, this shall not
                        constitute a waiver of such rights or remedies
                        and shall not relieve you from compliance with
                        such obligations. A waiver by us of any default
                        shall not constitute a waiver of any subsequent
                        default arising from the Contract or the Terms.
                        No waiver by us of any of these Terms shall be
                        effective unless it is expressly stated to be a
                        waiver and is communicated to you in writing in
                        accordance with the paragraph on Notices
                        above.</span
                      >
                    </p>
                    <h2 id="h.lcahpnpatxrm" className="c8">
                      <span className="c3">16. Severability</span>
                    </h2>
                    <p className="c4">
                      <span className="c0"
                        >If any of these Terms or any provisions of the
                        Contract are determined by any competent
                        authority to be invalid, unlawful or
                        unenforceable to any extent, such term,
                        condition or provision will to that extent be
                        severed from the remaining terms, conditions and
                        provisions which will continue to be valid to
                        the fullest extent permitted by law.</span
                      >
                    </p>
                    <h2 id="h.l8ivieuso3nh" className="c8">
                      <span className="c3">17. Law and Jurisdiction</span>
                    </h2>
                    <p className="c4">
                      <span className="c5"
                        >The use of our website and the Contracts for
                        the purchase of the plans through such websites
                        will be governed by Nigerian Law. Any dispute
                        arising from, or related to the use of this
                        website or to such Contracts shall be subject to
                        the non-exclusive jurisdiction of the Nigerian
                        courts.</span
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}
export default TermsList;