import { toast } from "react-toastify";
import moment from "moment";
import React from "react";

const styleCardContainer = {
  display: "flex",
  justifyContent: "center",
  marginTop: "20px",
  marginBottom: "20px",
};

const styleCard = {
  width: "100%",
  maxWidth: "550px",
  minHeight: "300px",
  boxShadow: "1px 1px 1px 1px gray",
  borderRadius: 10,
  padding: 30,
};

const cardHeader = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "10%",
};

const messageBox = {
  width: "100%",
  maxWidth: "550px",
  borderRadius: 10,
  textAlign: "justify",
};
const promiseWrapper = (promise) => {
  return new Promise((resolve) => {
    promise
      .then(async (resp) => {
        if (resp.ok) return resp.json();
        throw await resp.json();
      })
      .then((resp) => {
        resolve([null, resp]);
      })
      .catch((err) => {
        resolve([err, null]);
      });
  });
};
const variables = {
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  PAYSTACK_KEY: process.env.REACT_APP_PAYSTACK_KEY,
  SUB_AMOUNT: Number(process.env.REACT_APP_SUB_AMOUNT),
  HEALTH_PLAN: process.env.REACT_APP_HEALTH_PLAN,
};
const CardView = ({
  subData,
  formData,
  imageData,
  subID,
  logo,
  avatar,
  partnerLogo,
  simImg,
}) => {
  return (
    <div style={styleCardContainer}>
      <section
        style={{ ...styleCard, position: "relative", overflow: "hidden" }}
      >
        <div style={cardHeader}>
          <img
            src={logo}
            alt="logo"
            style={{ objectFit: "contain", objectPosition: "center center" }}
            width="160"
          />
          <section>
            <img src={partnerLogo} alt="Partner Logo" width="150" />
          </section>
        </div>

        <div
          style={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
            zIndex: 2,
          }}
        >
          <section
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              zIndex: 2,
            }}
          >
            {imageData ? (
              <img
                src={imageData}
                alt="owner"
                style={{
                  width: 150,
                  height: 150,
                  objectFit: "cover",
                  objectPosition: "center top",
                  marginRight: 10,
                }}
              />
            ) : (
              <img
                src={avatar}
                alt="owner"
                style={{
                  width: 150,
                  height: 150,
                  objectFit: "cover",
                  objectPosition: "center top",
                  marginRight: 10,
                }}
              />
            )}
            <div>
              <h3 style={{ color: "green", fontWeight: "bold", fontSize: 24 }}>
                {formData.firstName}&nbsp;{formData.lastName}
              </h3>
              {subID ? (
                <p className="id-card-p">
                  Enrollee No: {`${subData.userId}_${subID}`}
                </p>
              ) : (
                <p className="id-card-p">Enrollee No: {subData.userId}</p>
              )}
              <p className="id-card-p">
                Policy No:{" "}
                {formData.policyNumber
                  ? formData.policyNumber.toUpperCase()
                  : ""}
              </p>
              <p className="id-card-p">Sex: {formData.sex}</p>
              <p className="id-card-p">
                Start Date: {moment(subData.createdAt).format("DD-MM-YY")}
              </p>
            </div>
          </section>

          <section style={{ position: "relative", zIndex: 2 }}>
            <img
              src={simImg}
              alt="bar code"
              style={{
                objectFit: "cover",
                objectPosition: "center center",
                paddingTop: "40px",
              }}
              width="60"
            />
          </section>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 40,
            position: "relative",
            zIndex: 2,
          }}
        >
          <section style={{ display: "flex", alignItems: "center" }}>
            {(() => {
              const { activated, terminated, expireAt } = subData;
              const today = new Date();
              if (
                activated &&
                !terminated &&
                moment(expireAt).isAfter(moment(today))
              ) {
                return (
                  <p
                    className="text-green-800 font-bold"
                    style={{ fontWeight: "bolder" }}
                  >
                    ACTIVE
                  </p>
                );
              } else if (!activated && !terminated) {
                return (
                  <p
                    className="text-blue-800 font-bold"
                    style={{ fontWeight: "bolder" }}
                  >
                    PENDING
                  </p>
                );
              } else if (
                activated &&
                !terminated &&
                moment(expireAt).isBefore(moment(today))
              ) {
                return (
                  <p
                    className="text-red-800 font-bold"
                    style={{ fontWeight: "bolder" }}
                  >
                    EXPIRED
                  </p>
                );
              } else if (terminated) {
                return (
                  <p
                    className="text-red-800 font-bold"
                    style={{ fontWeight: "bolder" }}
                  >
                    CANCELLED
                  </p>
                );
              }
            })()}
          </section>

          <section style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{ marginRight: 10, color: "#fff", fontWeight: "bolder" }}
            >
              <p>Validity</p>
            </div>
            <p style={{ color: "#fff" }}>
              {moment(subData.expireAt).format("DD/MM/YY")}
            </p>
          </section>
        </div>

        <div
          style={{
            borderLeft: "300px transparent solid",
            borderBottom: "300px #4FBE89 solid",
            width: 300,
            position: "absolute",
            bottom: 0,
            right: 0,
          }}
        />

        <div style={{ display: 'block' }} >
          <div
            style={{ marginTop: '0.5rem', marginBottom: '0.5rem', padding: 4, fontSize: 'smaller', width: '16rem', background: "#4fbe89", borderRadius: 50 }}
          >
            <p style={{ color: "#fff", textAlign: 'center' }} >OPTIMA SUPPORT LINE</p>
          </div>
          <p style={{ fontWeight: "800", fontSize: 'small' }} >
            08100090895 OR 09114906867
          </p>
        </div>
      </section>
    </div>
  );
};

export const View = (props) => {
  const [subData, setSubData] = React.useState({
    form: {
      id: "",
      policyNumber: "",
      hmoId: "",
      firstName: "",
      lastName: "",
      sex: "",
      familyMembers: [],
    },
    subscription: {
      createdAt: "",
      userId: "",
      expireAt: "",
      terminated: false,
      activated: false,
    },
    loading: false,
    error: false,
    loaded: false,
  });

  const [imageData, setImageData] = React.useState({
    images: [],
  });
  const [subs, setSubs] = React.useState([]);

  React.useEffect(() => {
    const task = async () => {
      const userID = props.id;
      toast.loading("loading data....");
      // load subscription data
      const [err, resp] = await promiseWrapper(
        fetch(`${variables.API_BASE_URL}/yondaCare/subscriptions/${userID}`, {
          method: "GET",
        })
      );
      if (err || resp?.response?.items === null) {
        toast.dismiss();
        toast.error("Card ID not found!");
        setSubData({ ...subData, loading: false, error: true, loaded: true });
      } else {
        const subscriptions = resp.response.items.filter((sub) => {
          return sub.form.id !== "" ? sub : undefined;
        });
        const user = subscriptions.pop();

        await getCardImages(subscriptions);
        setSubData({ ...user, loading: false, loaded: true });
        // load image data
        const [imageErr, imageResp] = await promiseWrapper(
          fetch(`${variables.API_BASE_URL}/forms/photo/${user.form.id}`, {
            method: "GET",
          })
        );
        toast.dismiss();
        if (!imageErr) {
          setImageData({ images: imageResp.response });
          toast.success("data loaded successfully");
        } else {
          toast.dismiss();
          toast.error("Card ID not found!");
        }
      }
    };
    if (!subData.loaded && !subData.loading && props.ready) {
      setSubData({ ...subData, loading: true });
      task();
    }
  }, [subData, props.id, props.ready]);

  const getCardImages = async (data) => {
    const cardData = [];

    await Promise.all(
      data.map(async (card) => {
        await fetch(`${variables.API_BASE_URL}/forms/photo/${card.form.id}`, {
          method: "GET",
        })
          .then((r) => r.json())
          .then((response) => {
            card.imageSrc = response.response[0];
            cardData.push(card);
          });
      })
    );
    setSubs(cardData);
  };
  return (
    <main id="main" className="site-main clr" role="main">
      <div
        data-elementor-type="wp-page"
        data-elementor-id={14}
        className="elementor elementor-14"
        data-elementor-settings="[]"
      >
        <div className="elementor-section-wrap">
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-703d053 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="703d053"
            data-element_type="section"
            data-settings='{"jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"24be2de","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}],"background_background":"classic"}'
          >
            <div>
              <div style={styleCardContainer}>
                {!subData.subscription.activated &&
                  !subData.subscription.terminated &&
                  !subData.loading &&
                  !subData.error && (
                    <p style={messageBox}>
                      <span>
                        Dear {subData.form.firstName}&nbsp;
                        {subData.form.lastName}, you have successfully purchased
                        Insurance via YondaCare! We have sent instructions to
                        your registered email and SMS. See a copy of your
                        Digital ID Card below.
                      </span>
                      <br />
                    </p>
                  )}
                {subData.error && (
                  <p style={messageBox}>
                    <span>ID card not found</span>
                    <br />
                  </p>
                )}
              </div>
              {!subData.loading && !subData.error && (
                <CardView
                  subData={subData.subscription}
                  formData={subData.form}
                  imageData={imageData.images[0]}
                  logo={`${process.env.PUBLIC_URL}/images/yondacareblack.png`}
                  partnerLogo={`${process.env.PUBLIC_URL}/images/optima.png`}
                  avatar={`${process.env.PUBLIC_URL}/images/avatar.png`}
                  simImg={`${process.env.PUBLIC_URL}/images/sim.png`}
                />
              )}
              {subs.map((member, i) => {
                return (
                  <CardView
                    subData={member.subscription}
                    formData={member.form}
                    imageData={member.imageSrc}
                    subID={String(i + 1)}
                    key={i}
                    logo={`${process.env.PUBLIC_URL}/images/yondacareblack.png`}
                    partnerLogo={`${process.env.PUBLIC_URL}/images/partner.png`}
                    avatar={`${process.env.PUBLIC_URL}/images/avatar.png`}
                    simImg={`${process.env.PUBLIC_URL}/images/sim.png`}
                  />
                );
              })}
              {subData.form.familyMember && subData.form.familyMembers.map((member, i) => {
                return (
                  <CardView
                    subData={subData.subscription}
                    formData={member}
                    imageData={imageData.images[i + 1]}
                    subID={String(i + 1)}
                    key={i}
                  />
                );
              })}
            </div>
          </section>
        </div>
      </div>
    </main>
  );
};

// const domContainer = document.querySelector('#id-card-container');
// if (domContainer) ReactDOM.render(e(View), domContainer);
