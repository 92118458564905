/* eslint-disable */
const FAQS = () => {
  return (
    <>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-99cce3f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="99cce3f"
        data-element_type="section"
        data-settings='{"jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"b09d2e7","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}],"background_background":"classic"}'
      >
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-36bec23"
            data-id="36bec23"
            data-element_type="column"
          >
            <div
              className="elementor-widget-wrap elementor-element-populated"
            >
              <div
                className="elementor-element elementor-element-5d7a557 elementor-widget elementor-widget-heading"
                data-id="5d7a557"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h2
                    className="elementor-heading-title elementor-size-default"
                  >
                    Getting started
                  </h2>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-a628c4e elementor-widget elementor-widget-accordion"
                data-id="a628c4e"
                data-element_type="widget"
                data-widget_type="accordion.default"
              >
                <div className="elementor-widget-container">
                  <style>

                  </style>
                  <div className="elementor-accordion" role="tablist">
                    <div className="elementor-accordion-item">
                      <div
                        id="elementor-tab-title-1741"
                        className="elementor-tab-title"
                        data-tab="1"
                        role="tab"
                        aria-controls="elementor-tab-content-1741"
                        aria-expanded="false"
                      >
                        <span
                          className="elementor-accordion-icon elementor-accordion-icon-right"
                          aria-hidden="true"
                        >
                          <span className="elementor-accordion-icon-closed"
                          ><i className="fas fa-chevron-down"></i
                          ></span>
                          <span className="elementor-accordion-icon-opened"
                          ><i className="fas fa-chevron-up"></i
                          ></span>
                        </span>
                        <a className="elementor-accordion-title" href=""
                        >How does YondaCare work?</a
                        >
                      </div>
                      <div
                        id="elementor-tab-content-1741"
                        className="elementor-tab-content elementor-clearfix"
                        data-tab="1"
                        role="tabpanel"
                        aria-labelledby="elementor-tab-title-1741"
                      >
                        <p>
                          <span style={{ fontWeight: 400 }}
                          >Follow the steps below:</span
                          >
                        </p>
                        <ol>
                          <li style={{ fontWeight: 400 }} aria-level="1">
                            <span style={{ fontWeight: 400 }}
                            >Simply Visit the site by clicking </span
                            ><a href="https://dev-yonda.ajocard.com/"
                            ><span style={{ fontWeight: 400 }}
                            >here</span
                              ></a
                            >
                          </li>
                          <li style={{ fontWeight: 400 }} aria-level="1">
                            <span style={{ fontWeight: 400 }}
                            >Click on the “Get Started” button. </span
                            ><span style={{ fontWeight: 400 }}
                            >The process takes less than 3-5
                              minutes.</span
                            >
                          </li>
                          <li style={{ fontWeight: 400 }} aria-level="1">
                            <span style={{ fontWeight: 400 }}
                            >Snap a selfie, fill-out the enrollee form
                              (can add multiple within same page)</span
                            >
                          </li>
                          <li style={{ fontWeight: 400 }} aria-level="1">
                            <span style={{ fontWeight: 400 }}
                            >Submit the form and checkout</span
                            >
                          </li>
                          <li style={{ fontWeight: 400 }} aria-level="1">
                            <span style={{ fontWeight: 400 }}
                            >Pay with</span
                            ><span style={{ fontWeight: 400 }}>
                              your ATM card</span
                            ><span style={{ fontWeight: 400 }}>
                              and get a receipt instantly via
                              email)</span
                            >
                          </li>
                          <li style={{ fontWeight: 400 }} aria-level="1">
                            <span style={{ fontWeight: 400 }}
                            >Enrollee is automatically taken to a
                              success page that displays their Digital
                              ID card</span
                            >
                          </li>
                          <li style={{ fontWeight: 400 }} aria-level="1">
                            <span style={{ fontWeight: 400 }}
                            >Enrollee also receives a welcome Email
                              and SMS with a service status advise</span
                            >
                          </li>
                          <li style={{ fontWeight: 400 }} aria-level="1">
                            <span style={{ fontWeight: 400 }}
                            >Enrollees can subsequently visit the
                              Digital ID card page to check their
                              status. They can also show this page at a
                              hospital to access healthcare.</span
                            >
                          </li>
                        </ol>
                      </div>
                    </div>
                    <div className="elementor-accordion-item">
                      <div
                        id="elementor-tab-title-1742"
                        className="elementor-tab-title"
                        data-tab="2"
                        role="tab"
                        aria-controls="elementor-tab-content-1742"
                        aria-expanded="false"
                      >
                        <span
                          className="elementor-accordion-icon elementor-accordion-icon-right"
                          aria-hidden="true"
                        >
                          <span className="elementor-accordion-icon-closed"
                          ><i className="fas fa-chevron-down"></i
                          ></span>
                          <span className="elementor-accordion-icon-opened"
                          ><i className="fas fa-chevron-up"></i
                          ></span>
                        </span>
                        <a className="elementor-accordion-title" href=""
                        >How do I buy YondaCare Health Insurance?</a
                        >
                      </div>
                      <div
                        id="elementor-tab-content-1742"
                        className="elementor-tab-content elementor-clearfix"
                        data-tab="2"
                        role="tabpanel"
                        aria-labelledby="elementor-tab-title-1742"
                      >
                        <p>
                          <span style={{ fontWeight: 400 }}
                          >If you are interested in buying health
                            insurance for your company, please call </span
                          ><b>08097627028</b
                          ><span style={{ fontWeight: 400 }}>
                            to request a quote.</span
                          >
                        </p>
                        <p>
                          <span style={{ fontWeight: 400 }}
                          >You can also always connect with a
                            YondaCare agent who will be happy to help
                            you purchase insurance. To do so, reach out
                            via our contact details:</span
                          >
                        </p>
                        <p>
                          <b>Telephone:</b
                          ><span style={{ fontWeight: 400 }}>
                            08097627028</span
                          >
                        </p>
                        <p>
                          <b>Whatsapp:</b>
                          <a
                            href="https://api.whatsapp.com/send?phone=2347035369587"
                          ><span style={{ fontWeight: 400 }}
                          >08097627028</span
                            ></a
                          >
                        </p>
                      </div>
                    </div>
                    <div className="elementor-accordion-item">
                      <div
                        id="elementor-tab-title-1743"
                        className="elementor-tab-title"
                        data-tab="3"
                        role="tab"
                        aria-controls="elementor-tab-content-1743"
                        aria-expanded="false"
                      >
                        <span
                          className="elementor-accordion-icon elementor-accordion-icon-right"
                          aria-hidden="true"
                        >
                          <span className="elementor-accordion-icon-closed"
                          ><i className="fas fa-chevron-down"></i
                          ></span>
                          <span className="elementor-accordion-icon-opened"
                          ><i className="fas fa-chevron-up"></i
                          ></span>
                        </span>
                        <a className="elementor-accordion-title" href=""
                        >How do I buy YondaCare health insurance for
                          more than myself?</a
                        >
                      </div>
                      <div
                        id="elementor-tab-content-1743"
                        className="elementor-tab-content elementor-clearfix"
                        data-tab="3"
                        role="tabpanel"
                        aria-labelledby="elementor-tab-title-1743"
                      >
                        <p>
                          <span style={{ fontWeight: 400 }}
                          >On the enrollment form, Click on the
                            “</span
                          ><b>Add enrollee</b
                          ><span style={{ fontWeight: 400 }}
                          >” button at the bottom of the first form.
                            Fill in the relevant information for each
                            person required by the form, submit and you
                            are done.You can add as many enrollees as
                            possible for now.</span
                          >
                        </p>
                      </div>
                    </div>
                    <div className="elementor-accordion-item">
                      <div
                        id="elementor-tab-title-1744"
                        className="elementor-tab-title"
                        data-tab="4"
                        role="tab"
                        aria-controls="elementor-tab-content-1744"
                        aria-expanded="false"
                      >
                        <span
                          className="elementor-accordion-icon elementor-accordion-icon-right"
                          aria-hidden="true"
                        >
                          <span className="elementor-accordion-icon-closed"
                          ><i className="fas fa-chevron-down"></i
                          ></span>
                          <span className="elementor-accordion-icon-opened"
                          ><i className="fas fa-chevron-up"></i
                          ></span>
                        </span>
                        <a className="elementor-accordion-title" href=""
                        >How do I select a Hospital?</a
                        >
                      </div>
                      <div
                        id="elementor-tab-content-1744"
                        className="elementor-tab-content elementor-clearfix"
                        data-tab="4"
                        role="tabpanel"
                        aria-labelledby="elementor-tab-title-1744"
                      >
                        <p>
                          <span style={{ fontWeight: 400 }}
                          >You select your primary hospital from a
                            list of available hospitals when you are
                            filling out the enrolment form. It is best
                            you choose a hospital close to your home,
                            place of work or anywhere you deem
                            convenient.</span
                          >
                        </p>
                      </div>
                    </div>
                    <div className="elementor-accordion-item">
                      <div
                        id="elementor-tab-title-1745"
                        className="elementor-tab-title"
                        data-tab="5"
                        role="tab"
                        aria-controls="elementor-tab-content-1745"
                        aria-expanded="false"
                      >
                        <span
                          className="elementor-accordion-icon elementor-accordion-icon-right"
                          aria-hidden="true"
                        >
                          <span className="elementor-accordion-icon-closed"
                          ><i className="fas fa-chevron-down"></i
                          ></span>
                          <span className="elementor-accordion-icon-opened"
                          ><i className="fas fa-chevron-up"></i
                          ></span>
                        </span>
                        <a className="elementor-accordion-title" href=""
                        >Is my Card information safe?</a
                        >
                      </div>
                      <div
                        id="elementor-tab-content-1745"
                        className="elementor-tab-content elementor-clearfix"
                        data-tab="5"
                        role="tabpanel"
                        aria-labelledby="elementor-tab-title-1745"
                      >
                        <p>
                          <span style={{ fontWeight: 400 }}
                          >Absolutely! YondaCare does not collect,
                            store or use your card information in any
                            way. The information you provided is
                            instantly used to process your payment for
                            the health cover and then destroyed.</span
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-98e27de elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="98e27de"
        data-element_type="section"
        data-settings='{"jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"b09d2e7","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}],"background_background":"classic"}'
      >
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f1abb4e"
            data-id="f1abb4e"
            data-element_type="column"
          >
            <div
              className="elementor-widget-wrap elementor-element-populated"
            >
              <div
                className="elementor-element elementor-element-969673f elementor-widget elementor-widget-heading"
                data-id="969673f"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h2
                    className="elementor-heading-title elementor-size-default"
                  >
                    Accessing Care
                  </h2>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-0d44b8d elementor-widget elementor-widget-accordion"
                data-id="0d44b8d"
                data-element_type="widget"
                data-widget_type="accordion.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-accordion" role="tablist">
                    <div className="elementor-accordion-item">
                      <div
                        id="elementor-tab-title-1391"
                        className="elementor-tab-title"
                        data-tab="1"
                        role="tab"
                        aria-controls="elementor-tab-content-1391"
                        aria-expanded="false"
                      >
                        <span
                          className="elementor-accordion-icon elementor-accordion-icon-right"
                          aria-hidden="true"
                        >
                          <span className="elementor-accordion-icon-closed"
                          ><i className="fas fa-chevron-down"></i
                          ></span>
                          <span className="elementor-accordion-icon-opened"
                          ><i className="fas fa-chevron-up"></i
                          ></span>
                        </span>
                        <a className="elementor-accordion-title" href=""
                        >Do I need to call you first before I go to
                          the hospital?</a
                        >
                      </div>
                      <div
                        id="elementor-tab-content-1391"
                        className="elementor-tab-content elementor-clearfix"
                        data-tab="1"
                        role="tabpanel"
                        aria-labelledby="elementor-tab-title-1391"
                      >
                        No, you do not. Within 24 hours of purchasing your YondaCare plan,
                        you can walk confidently into your chosen hospital,
                        show your Digital (soft) ID card and you will be attended to.
                        If you ever face any issues in the hospital, reach out to us immediately.
                      </div>
                    </div>
                    <div className="elementor-accordion-item">
                      <div
                        id="elementor-tab-title-1743"
                        className="elementor-tab-title"
                        data-tab="3"
                        role="tab"
                        aria-controls="elementor-tab-content-1743"
                        aria-expanded="false"
                      >
                        <span
                          className="elementor-accordion-icon elementor-accordion-icon-right"
                          aria-hidden="true"
                        >
                          <span className="elementor-accordion-icon-closed"
                          ><i className="fas fa-chevron-down"></i
                          ></span>
                          <span className="elementor-accordion-icon-opened"
                          ><i className="fas fa-chevron-up"></i
                          ></span>
                        </span>
                        <a className="elementor-accordion-title" href=""
                        >Who do I call for hospitals issues or clarifications?</a
                        >
                      </div>
                      <div
                        id="elementor-tab-content-1743"
                        className="elementor-tab-content elementor-clearfix"
                        data-tab="3"
                        role="tabpanel"
                        aria-labelledby="elementor-tab-title-1743"
                      >
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Call Optima Care support line on{' '}
                            <a href="tel:08100090895">08100090895</a> or <a href="tel:09114906867" >09114906867</a>
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="elementor-accordion-item">
                      <div
                        id="elementor-tab-title-1392"
                        className="elementor-tab-title"
                        data-tab="2"
                        role="tab"
                        aria-controls="elementor-tab-content-1392"
                        aria-expanded="false"
                      >
                        <span
                          className="elementor-accordion-icon elementor-accordion-icon-right"
                          aria-hidden="true"
                        >
                          <span className="elementor-accordion-icon-closed"
                          ><i className="fas fa-chevron-down"></i
                          ></span>
                          <span className="elementor-accordion-icon-opened"
                          ><i className="fas fa-chevron-up"></i
                          ></span>
                        </span>
                        <a className="elementor-accordion-title" href=""
                        >Can I change my hospital at any time I
                          desire?</a
                        >
                      </div>
                      <div
                        id="elementor-tab-content-1392"
                        className="elementor-tab-content elementor-clearfix"
                        data-tab="2"
                        role="tabpanel"
                        aria-labelledby="elementor-tab-title-1392"
                      >
                        Yes. you can freely access care in any hospital
                        on our list. To make enquiries and effect a
                        change of hospital please call 08097627028
                      </div>
                    </div>
                    <div className="elementor-accordion-item">
                      <div
                        id="elementor-tab-title-1393"
                        className="elementor-tab-title"
                        data-tab="3"
                        role="tab"
                        aria-controls="elementor-tab-content-1393"
                        aria-expanded="false"
                      >
                        <span
                          className="elementor-accordion-icon elementor-accordion-icon-right"
                          aria-hidden="true"
                        >
                          <span className="elementor-accordion-icon-closed"
                          ><i className="fas fa-chevron-down"></i
                          ></span>
                          <span className="elementor-accordion-icon-opened"
                          ><i className="fas fa-chevron-up"></i
                          ></span>
                        </span>
                        <a className="elementor-accordion-title" href=""
                        >Can I visit any hospital when I travel to
                          other states?</a
                        >
                      </div>
                      <div
                        id="elementor-tab-content-1393"
                        className="elementor-tab-content elementor-clearfix"
                        data-tab="3"
                        role="tabpanel"
                        aria-labelledby="elementor-tab-title-1393"
                      >
                        Yes. You are allowed to visit all hospitals in
                        the YondaCare network that are available where
                        you travel to. You can check the list of
                        YondaCare network hospitals and clinics near you
                        on our website. Our Help Centre agent can also
                        help you find a hospital nearby if you reach out
                        to us.
                      </div>
                    </div>
                    <div className="elementor-accordion-item">
                      <div
                        id="elementor-tab-title-1394"
                        className="elementor-tab-title"
                        data-tab="4"
                        role="tab"
                        aria-controls="elementor-tab-content-1394"
                        aria-expanded="false"
                      >
                        <span
                          className="elementor-accordion-icon elementor-accordion-icon-right"
                          aria-hidden="true"
                        >
                          <span className="elementor-accordion-icon-closed"
                          ><i className="fas fa-chevron-down"></i
                          ></span>
                          <span className="elementor-accordion-icon-opened"
                          ><i className="fas fa-chevron-up"></i
                          ></span>
                        </span>
                        <a className="elementor-accordion-title" href=""
                        >Does my YondaCare ID card guarantee access to
                          care?</a
                        >
                      </div>
                      <div
                        id="elementor-tab-content-1394"
                        className="elementor-tab-content elementor-clearfix"
                        data-tab="4"
                        role="tabpanel"
                        aria-labelledby="elementor-tab-title-1394"
                      >
                        <p>
                          <span style={{ fontWeight: 400 }}
                          >Yes, it does! If you present the YondaCare
                            (soft) ID card on your phone at your chosen
                            hospital, you will receive care so long as
                            your insurance is active and you are
                            eligible and covered for the service you
                            need to receive in the hospitalC</span
                          >
                        </p>
                        <p>
                          <span style={{ fontWeight: 400 }}
                          >If you ever face any issues in the
                            hospital, immediately reach out to us
                            through our contact details at the bottom of
                            this page. A YondaCare agent will resolve
                            the issue speedily</span
                          >
                        </p>
                      </div>
                    </div>
                    <div className="elementor-accordion-item">
                      <div
                        id="elementor-tab-title-1395"
                        className="elementor-tab-title"
                        data-tab="5"
                        role="tab"
                        aria-controls="elementor-tab-content-1395"
                        aria-expanded="false"
                      >
                        <span
                          className="elementor-accordion-icon elementor-accordion-icon-right"
                          aria-hidden="true"
                        >
                          <span className="elementor-accordion-icon-closed"
                          ><i className="fas fa-chevron-down"></i
                          ></span>
                          <span className="elementor-accordion-icon-opened"
                          ><i className="fas fa-chevron-up"></i
                          ></span>
                        </span>
                        <a className="elementor-accordion-title" href=""
                        >Why don’t you issue hard copy ID cards?</a
                        >
                      </div>
                      <div
                        id="elementor-tab-content-1395"
                        className="elementor-tab-content elementor-clearfix"
                        data-tab="5"
                        role="tabpanel"
                        aria-labelledby="elementor-tab-title-1395"
                      >
                        YondaCare soft copy ID cards allow us to offer
                        you instant coverage and make the process of
                        accessing care simple and convenient. Hard copy
                        ID cards don’t allow this to happen. Generally,
                        Digital ID Cards have more advantages than hard
                        copy cards.
                      </div>
                    </div>
                    <div className="elementor-accordion-item">
                      <div
                        id="elementor-tab-title-1396"
                        className="elementor-tab-title"
                        data-tab="6"
                        role="tab"
                        aria-controls="elementor-tab-content-1396"
                        aria-expanded="false"
                      >
                        <span
                          className="elementor-accordion-icon elementor-accordion-icon-right"
                          aria-hidden="true"
                        >
                          <span className="elementor-accordion-icon-closed"
                          ><i className="fas fa-chevron-down"></i
                          ></span>
                          <span className="elementor-accordion-icon-opened"
                          ><i className="fas fa-chevron-up"></i
                          ></span>
                        </span>
                        <a className="elementor-accordion-title" href=""
                        >What happens if I visit the hospital without
                          my Digital ID card?</a
                        >
                      </div>
                      <div
                        id="elementor-tab-content-1396"
                        className="elementor-tab-content elementor-clearfix"
                        data-tab="6"
                        role="tabpanel"
                        aria-labelledby="elementor-tab-title-1396"
                      >
                        Ideally, if you visit the hospital without your
                        Digital (soft) ID card, you would not be able to
                        access medical care. Since our promise to you is
                        that affordable and quality healthcare is always
                        assured with YondaCare. So, whenever you visit
                        the hospital without your YondaCare Digital
                        (soft) ID card, you are expected to at least
                        know your policy ID number. However, in cases
                        where you cannot provide both your YondaCare
                        digital (soft) ID card and policy number, you
                        can simply reach out to our Customer help
                        desk/Support via the contact information shared
                        below.
                      </div>
                    </div>
                    <div className="elementor-accordion-item">
                      <div
                        id="elementor-tab-title-1397"
                        className="elementor-tab-title"
                        data-tab="7"
                        role="tab"
                        aria-controls="elementor-tab-content-1397"
                        aria-expanded="false"
                      >
                        <span
                          className="elementor-accordion-icon elementor-accordion-icon-right"
                          aria-hidden="true"
                        >
                          <span className="elementor-accordion-icon-closed"
                          ><i className="fas fa-chevron-down"></i
                          ></span>
                          <span className="elementor-accordion-icon-opened"
                          ><i className="fas fa-chevron-up"></i
                          ></span>
                        </span>
                        <a className="elementor-accordion-title" href=""
                        >How do I access YondaCare?
                        </a>
                      </div>
                      <div
                        id="elementor-tab-content-1397"
                        className="elementor-tab-content elementor-clearfix"
                        data-tab="7"
                        role="tabpanel"
                        aria-labelledby="elementor-tab-title-1397"
                      >
                        <p>
                          <span style={{ fontWeight: 400 }}
                          >You can access the YondaCare on your web
                            browser by visiting </span
                          ><a
                            href="https://www.reliancehmo.com/dashboard.reliancehmo.com"
                          ><span style={{ fontWeight: 400 }}
                          >https://dev-yonda.ajocard.com/</span
                            ></a
                          >&nbsp;
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-254b687 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="254b687"
        data-element_type="section"
        data-settings='{"jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"b09d2e7","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}],"background_background":"classic"}'
      >
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-85ac0d0"
            data-id="85ac0d0"
            data-element_type="column"
          >
            <div
              className="elementor-widget-wrap elementor-element-populated"
            >
              <div
                className="elementor-element elementor-element-280b89c elementor-widget elementor-widget-heading"
                data-id="280b89c"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h2
                    className="elementor-heading-title elementor-size-default"
                  >
                    Benefits
                  </h2>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-217b101 elementor-widget elementor-widget-accordion"
                data-id="217b101"
                data-element_type="widget"
                data-widget_type="accordion.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-accordion" role="tablist">
                    <div className="elementor-accordion-item">
                      <div
                        id="elementor-tab-title-3511"
                        className="elementor-tab-title"
                        data-tab="1"
                        role="tab"
                        aria-controls="elementor-tab-content-3511"
                        aria-expanded="false"
                      >
                        <span
                          className="elementor-accordion-icon elementor-accordion-icon-right"
                          aria-hidden="true"
                        >
                          <span className="elementor-accordion-icon-closed"
                          ><i className="fas fa-chevron-down"></i
                          ></span>
                          <span className="elementor-accordion-icon-opened"
                          ><i className="fas fa-chevron-up"></i
                          ></span>
                        </span>
                        <a className="elementor-accordion-title" href=""
                        >What health benefits do I have access to?</a
                        >
                      </div>
                      <div
                        id="elementor-tab-content-3511"
                        className="elementor-tab-content elementor-clearfix"
                        data-tab="1"
                        role="tabpanel"
                        aria-labelledby="elementor-tab-title-3511"
                      >
                        Your health benefits include general
                        consultations and lots more. You can click here
                        to view the list of health benefits on our
                        health insurance plans.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-07971f9 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="07971f9"
        data-element_type="section"
        data-settings='{"jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"b09d2e7","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}],"background_background":"classic"}'
      >
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-60aa294"
            data-id="60aa294"
            data-element_type="column"
          >
            <div
              className="elementor-widget-wrap elementor-element-populated"
            >
              <div
                className="elementor-element elementor-element-8c82676 elementor-widget elementor-widget-heading"
                data-id="8c82676"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h2
                    className="elementor-heading-title elementor-size-default"
                  >
                    Additional information
                  </h2>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-ca91988 elementor-widget elementor-widget-accordion"
                data-id="ca91988"
                data-element_type="widget"
                data-widget_type="accordion.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-accordion" role="tablist">
                    <div className="elementor-accordion-item">
                      <div
                        id="elementor-tab-title-2121"
                        className="elementor-tab-title"
                        data-tab="1"
                        role="tab"
                        aria-controls="elementor-tab-content-2121"
                        aria-expanded="false"
                      >
                        <span
                          className="elementor-accordion-icon elementor-accordion-icon-right"
                          aria-hidden="true"
                        >
                          <span className="elementor-accordion-icon-closed"
                          ><i className="fas fa-chevron-down"></i
                          ></span>
                          <span className="elementor-accordion-icon-opened"
                          ><i className="fas fa-chevron-up"></i
                          ></span>
                        </span>
                        <a className="elementor-accordion-title" href=""
                        >What if I don't use my insurance?</a
                        >
                      </div>
                      <div
                        id="elementor-tab-content-2121"
                        className="elementor-tab-content elementor-clearfix"
                        data-tab="1"
                        role="tabpanel"
                        aria-labelledby="elementor-tab-title-2121"
                      >
                        <p>We DO NOT run a cash back program.</p>
                        <h2>
                          <b>Can I cancel my insurance at any time?</b>
                        </h2>
                        <p>
                          We’re sorry to see you go and would encourage
                          you to continue to pay your premiums to enjoy
                          top-notch health insurance. However, if you
                          would like to cancel your insurance, you can
                          do so at any time and we will only cover you
                          for the period you have paid.
                        </p>
                        <p>
                          You can renew your insurance after
                          cancellation, however, there will be a 5
                          business days wait period before your coverage
                          will commence for the period you paid
                          for.&nbsp;
                        </p>
                        <p>
                          If you would like to have further
                          clarification on any of this, feel free to
                          call YondaCare Centre on 08097627028
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-00f1c94 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="00f1c94"
        data-element_type="section"
        data-settings='{"jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"b09d2e7","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}],"background_background":"classic"}'
      >
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ed7a5fe"
            data-id="ed7a5fe"
            data-element_type="column"
          >
            <div
              className="elementor-widget-wrap elementor-element-populated"
            >
              <div
                className="elementor-element elementor-element-8efbded elementor-widget elementor-widget-heading"
                data-id="8efbded"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h2
                    className="elementor-heading-title elementor-size-default"
                  >
                    Contacting us
                  </h2>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-ab58e65 elementor-widget elementor-widget-accordion"
                data-id="ab58e65"
                data-element_type="widget"
                data-widget_type="accordion.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-accordion" role="tablist">
                    <div className="elementor-accordion-item">
                      <div
                        id="elementor-tab-title-1791"
                        className="elementor-tab-title"
                        data-tab="1"
                        role="tab"
                        aria-controls="elementor-tab-content-1791"
                        aria-expanded="false"
                      >
                        <span
                          className="elementor-accordion-icon elementor-accordion-icon-right"
                          aria-hidden="true"
                        >
                          <span className="elementor-accordion-icon-closed"
                          ><i className="fas fa-chevron-down"></i
                          ></span>
                          <span className="elementor-accordion-icon-opened"
                          ><i className="fas fa-chevron-up"></i
                          ></span>
                        </span>
                        <a className="elementor-accordion-title" href=""
                        >How do I contact YondaCare?</a
                        >
                      </div>
                      <div
                        id="elementor-tab-content-1791"
                        className="elementor-tab-content elementor-clearfix"
                        data-tab="1"
                        role="tabpanel"
                        aria-labelledby="elementor-tab-title-1791"
                      >
                        <p>
                          If you need to speak to us about your health
                          benefits, payment, enrollment, insurance plans
                          or any other issue at all, a Reliance Care
                          Centre agent is available 24 hours a day to
                          answer you. You can easily reach out to us via
                        </p>
                        <p><b>Telephone: </b>08097627028</p>
                        <p>
                          <b>Whatsapp:</b>
                          <a
                            href="https://api.whatsapp.com/send?phone=2347035369587"
                          >08097627028</a
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default FAQS;