const WhyYonda = () => {
    return (
        <section
                className="elementor-section elementor-top-section elementor-element elementor-element-6a6b186 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="6a6b186"
                data-element_type="section"
                data-settings='{"jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"e5fce98","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}],"background_background":"classic"}'
                id="why-yonda"
              >
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7f2e1fa"
                    data-id="7f2e1fa"
                    data-element_type="column"
                  >
                    <div
                      className="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        className="elementor-element elementor-element-e53078e elementor-widget elementor-widget-heading"
                        data-id="e53078e"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <style>
                           
                          </style>
                          <h2
                            className="elementor-heading-title elementor-size-default"
                          >
                            Why YondaCare
                          </h2>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-c9d84ee elementor-widget elementor-widget-text-editor"
                        data-id="c9d84ee"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div className="elementor-widget-container">
                          <style>
                            
                          </style>
                          <p>
                            YondaCare health insurance offers a single health
                            care plan for as little as N1500 monthly, so that
                            your loved ones and you, can enjoy the benefit of
                            our over 750+ hospitals nationwide by simply
                            presenting our virtual ID card issued to you through
                            your smartphone.
                          </p>
                          <p>
                            Our YondaCare sign-up process is as easy as 1-2-3
                            with no need for any paperwork.&nbsp;
                          </p>
                          <p>
                            We believe that no mother and child, teenager or
                            adult should operate below their true potential in
                            our dear country – Nigeria, due to the high cost of
                            hospital bills or poor access to care during an
                            emergency.
                          </p>
                          <p>
                            Our plan is backed by a leading insurance company,
                            providing quality medical coverage and great
                            customer service to all our customers.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
    )
}
export default WhyYonda;