import React from 'react';
import ElementorContainer from '../components/ElementorContainer';
import { View } from '../components/idCard';
import Layout from '../components/Layout';
import { useParams } from 'react-router';

const IdCard = () => {
    const {userID} = useParams();
    return (
        <Layout title="ID card">
                <ElementorContainer id="159">
                    <View id={userID} ready={userID ? true : false}/>
                </ElementorContainer>
        </Layout>
    )
}
export default IdCard;