const Presence = () => {
    return (
        <section
                className="elementor-section elementor-top-section elementor-element elementor-element-c0e3e96 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="c0e3e96"
                data-element_type="section"
                data-settings='{"jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"0a9a13d","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}]}'
              >
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7229e77"
                    data-id="7229e77"
                    data-element_type="column"
                  >
                    <div
                      className="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        className="elementor-element elementor-element-f8e3965 elementor-widget elementor-widget-heading"
                        data-id="f8e3965"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h2
                            className="elementor-heading-title elementor-size-default"
                          >
                            Our Value Promise
                          </h2>
                        </div>
                      </div>
                      <section
                        className="elementor-section elementor-inner-section elementor-element elementor-element-d2e94f3 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="d2e94f3"
                        data-element_type="section"
                        data-settings='{"jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"b6a2b82","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}]}'
                      >
                        <div
                          className="elementor-container elementor-column-gap-default"
                        >
                          <div
                            className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-3eea5ab"
                            data-id="3eea5ab"
                            data-element_type="column"
                          >
                            <div
                              className="elementor-widget-wrap elementor-element-populated"
                            >
                              <div
                                className="elementor-element elementor-element-5d0be21 elementor-widget elementor-widget-text-editor"
                                data-id="5d0be21"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  <p>
                                    <span style={{color: '#000000'}}
                                      >To consistently deliver hassle-free,
                                      affordable, easily accessible, quality
                                      healthcare solutions that help our
                                      customers’ enjoy the best quality of
                                      life.</span
                                    >
                                  </p>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-846c9da elementor-widget elementor-widget-text-editor"
                                data-id="846c9da"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  <p>
                                    <span style={{color: '#000000'}}
                                      >YondaCare has access to 750+ hospitals
                                      across 37 states of the country.</span
                                    >
                                  </p>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-644cecc elementor-widget__width-auto elementor-widget elementor-widget-counter"
                                data-id="644cecc"
                                data-element_type="widget"
                                data-widget_type="counter.default"
                              >
                                <div className="elementor-widget-container">
                                  <style>
                                    
                                  </style>
                                  <div className="elementor-counter">
                                    <div
                                      className="elementor-counter-number-wrapper"
                                    >
                                      <span
                                        className="elementor-counter-number-prefix"
                                      ></span>
                                      <span
                                        className="elementor-counter-number"
                                        data-duration="2000"
                                        data-to-value="37"
                                        data-from-value="0"
                                        data-delimiter=","
                                        >0</span
                                      >
                                      <span
                                        className="elementor-counter-number-suffix"
                                      ></span>
                                    </div>
                                    <div className="elementor-counter-title">
                                      State Served
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-d66d7ae elementor-widget__width-auto elementor-widget elementor-widget-counter"
                                data-id="d66d7ae"
                                data-element_type="widget"
                                data-widget_type="counter.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-counter">
                                    <div
                                      className="elementor-counter-number-wrapper"
                                    >
                                      <span
                                        className="elementor-counter-number-prefix"
                                      ></span>
                                      <span
                                        className="elementor-counter-number"
                                        data-duration="2000"
                                        data-to-value="200"
                                        data-from-value="0"
                                        data-delimiter=","
                                        >0</span
                                      >
                                      <span
                                        className="elementor-counter-number-suffix"
                                        >+</span
                                      >
                                    </div>
                                    <div className="elementor-counter-title">
                                      Citys Serve
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-d3d3c6f elementor-widget__width-auto elementor-widget elementor-widget-counter"
                                data-id="d3d3c6f"
                                data-element_type="widget"
                                data-widget_type="counter.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-counter">
                                    <div
                                      className="elementor-counter-number-wrapper"
                                    >
                                      <span
                                        className="elementor-counter-number-prefix"
                                      ></span>
                                      <span
                                        className="elementor-counter-number"
                                        data-duration="2000"
                                        data-to-value="750"
                                        data-from-value="0"
                                        data-delimiter=","
                                        >0</span
                                      >
                                      <span
                                        className="elementor-counter-number-suffix"
                                        >+</span
                                      >
                                    </div>
                                    <div className="elementor-counter-title">
                                      Hospitals
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-e45734d"
                            data-id="e45734d"
                            data-element_type="column"
                          >
                            <div
                              className="elementor-widget-wrap elementor-element-populated"
                            >
                              <div
                                className="elementor-element elementor-element-8af1423 elementor-widget elementor-widget-hotspot"
                                data-id="8af1423"
                                data-element_type="widget"
                                data-settings='{"hotspot":[{"hotspot_tooltip_content":"<p style=\"text-align: center;\">Lagos, Nigeria<\/p>","hotspot_offset_x":{"unit":"%","size":34,"sizes":[]},"hotspot_offset_y":{"unit":"%","size":38,"sizes":[]},"hotspot_tooltip_position":"","hotspot_tooltip_width":null,"_id":"ff66e02","hotspot_label":"","hotspot_link":{"url":"","is_external":"","nofollow":"","custom_attributes":""},"hotspot_icon":{"value":"","library":""},"hotspot_icon_position":null,"hotspot_icon_spacing":null,"hotspot_custom_size":"no","hotspot_width":null,"hotspot_height":null,"hotspot_horizontal":"left","hotspot_vertical":"top","hotspot_position":null,"hotspot_tooltip_text_wrap":null}],"tooltip_position":"bottom","tooltip_position_mobile":"bottom","tooltip_trigger":"mouseenter","tooltip_animation":"e-hotspot--fade-grow"}'
                                data-widget_type="hotspot.default"
                              >
                                <div className="elementor-widget-container">
                                  <link
                                    rel="stylesheet"
                                    href="css/widget-hotspot.min.css"
                                  /><img
                                    width="500"
                                    height="499"
                                    src="images/bgc_images-removebg-preview.png"
                                    className="attachment-full size-full"
                                    alt=""
                                    loading="lazy"
                                    srcSet="
                                      images/bgc_images-removebg-preview.png         500w,
                                      images/bgc_images-removebg-preview-300x300.png 300w,
                                      images/bgc_images-removebg-preview-150x150.png 150w
                                    "
                                    sizes="(max-width: 500px) 100vw, 500px"
                                  />
                                  <div
                                    className="e-hotspot elementor-repeater-item-ff66e02 e-hotspot--position-left e-hotspot--position-top e-hotspot--circle"
                                  >
                                    <div
                                      className="e-hotspot__button e-hotspot--expand"
                                    >
                                      <div
                                        className="e-hotspot__outer-circle"
                                      ></div>
                                      <div
                                        className="e-hotspot__inner-circle"
                                      ></div>
                                    </div>

                                    <div
                                      className="e-hotspot__tooltip e-hotspot--tooltip-position e-hotspot--fade-grow"
                                    >
                                      <p style={{textAlign: "center"}}>
                                        Lagos, Nigeria
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </section>
    )
}
export default Presence;