import React from 'react';
import ElementorContainer from '../components/ElementorContainer';
import Layout from '../components/Layout';
import PrivacyHero from '../components/privacy/hero';
import Policies from '../components/privacy/policies';

const PrivacyPolicy = () => {
    return (
        <Layout title="Privacy Policy">
                <ElementorContainer id="159">
                    <PrivacyHero/>
                    <Policies/>
                </ElementorContainer>
        </Layout>
    )
}
export default PrivacyPolicy;