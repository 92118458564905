import React from "react";
import { toast } from "react-toastify";
import ElementorContainer from "../components/ElementorContainer";
import Layout from "../components/Layout";
import PolicyCard from "../components/policyCard";

const variables = {
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  PAYSTACK_KEY: process.env.REACT_APP_PAYSTACK_KEY,
  SUB_AMOUNT: Number(process.env.REACT_APP_SUB_AMOUNT),
  HEALTH_PLAN: process.env.REACT_APP_HEALTH_PLAN,
};
const promiseWrapper = (promise) => {
  return new Promise((resolve) => {
    promise
      .then(async (resp) => {
        if (resp.ok) return resp.json();
        throw await resp.json();
      })
      .then((resp) => {
        resolve([null, resp]);
      })
      .catch((err) => {
        resolve([err, null]);
      });
  });
};

export const ValidatePolicy = (props) => {
  const [policyNumber, setPolicyNumber] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [policies, setPolicies] = React.useState([]);

  const searchPolicy = async () => {
    toast.dismiss();
    if (policyNumber.length < 6) {
      return toast.warning("Incorrect Policy Number");
    }
    setLoading(true); 
    const queryParams = policyNumber.includes('LCP') ? `policy=${policyNumber}` : `phone=${policyNumber}`;
    const [err, resp] = await promiseWrapper(
      fetch(
        `${variables.API_BASE_URL}/yondaCare/subscriptions?${queryParams}`,
        {
          method: "GET",
        }
      )
    );
    setLoading(false);
    if (!err) {
      setPolicies(resp.response.items||[]);
    } else {
      toast.error("Policy Number or Phone Number not found");
    }
  };
  return (
    <Layout title="Get started">
      <ElementorContainer id="1008">
        <main className="main-container">

          <div className="py-8 flex justify-center">
            <div className="form-card">
              <h2 className="page-title">Validate Policy Number</h2>
              <h6 className="page-details">
                Validate and renew your policy details
              </h6>
              <br />
              <div className="page-actions">
                <div className="search-box">
                  <input
                    type={"text"}
                    placeholder="Enter Policy Number or Phone Number"
                    name="policyNumber"
                    className="policyNumberInput"
                    onChange={(e) => setPolicyNumber(e.target.value)}
                    value={policyNumber}
                    disabled={loading}
                  />
                  <button
                    className="search-policy"
                    onClick={searchPolicy}
                    disabled={loading}
                  >
                    {loading ? <i className="fa fa-spinner fa-spin"></i> : <i className="fa fa-search"></i>}
                    &nbsp;
                    Search Policy Number 
                  </button>
                </div>
                {/* <div>
                Sort By: 
                <select>

                </select>
              </div> */}
              </div>
              <div className="form-responses">
                {
                  policies.map((policy)=> {
                    return (
                      <PolicyCard user={policy}/>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </main>
      </ElementorContainer>
    </Layout>
  );
};
