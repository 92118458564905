const AboutDetails = () => {
    return (
        <section
                className="elementor-section elementor-top-section elementor-element elementor-element-298e866 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="298e866"
                data-element_type="section"
                data-settings='{"jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"e5fce98","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}],"background_background":"classic"}'
              >
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-84867e9"
                    data-id="84867e9"
                    data-element_type="column"
                  >
                    <div
                      className="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        className="elementor-element elementor-element-d2d7ef5 elementor-widget elementor-widget-text-editor"
                        data-id="d2d7ef5"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div className="elementor-widget-container">
                          <p style={{textAlign: "left"}}>
                            YondaCare is an integrated healthcare solutions
                            start-up, transforming the way ‘the underserved’
                            assess basic healthcare across the country.&nbsp;
                          </p>
                          <p style={{textAlign: "left"}}>
                            <span style={{fontWeight: 400}}
                              >We believe that no mother and child, teenager or
                              adult should operate below their true potential
                              due to the high cost of hospital bills or poor
                              access to emergency care.</span
                            >
                          </p>
                          <p style={{textAlign: "left"}}>
                            We are committed to delivering a simple but very
                            efficient all inclusive, quality healthcare plan
                            that meets each of our customers’ basic health care
                            needs, especially at times when a health emergency
                            or an accident occurs.
                          </p>
                          <p style={{textAlign: 'center'}}>“Health is Wealth”</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
    )
}
export default AboutDetails;