import React from 'react';
import ElementorContainer from '../components/ElementorContainer';
import Layout from '../components/Layout';
import BenefitHero from '../components/benefits/hero';
import Plans from '../components/benefits/plans';
import Services from '../components/benefits/services';
import InsuranceBenefits from '../components/benefits/benefits';
const Benefits = () => {
    return (
        <Layout title="Yondacare Benefits">
                <ElementorContainer id="11">
                    <BenefitHero />
                    <Plans/>
                    <Services/>
                    <InsuranceBenefits />
                </ElementorContainer>
        </Layout>
    )
}
export default Benefits;