const Services = () => {
    return (
        <section
                className="elementor-section elementor-top-section elementor-element elementor-element-bbe91ae elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="bbe91ae"
                data-element_type="section"
                data-settings='{"jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"18d8544","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}],"background_background":"gradient"}'
              >
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-abe242c"
                    data-id="abe242c"
                    data-element_type="column"
                  >
                    <div
                      className="elementor-widget-wrap elementor-element-populated"
                    >
                      <section
                        className="elementor-section elementor-inner-section elementor-element elementor-element-4de1596 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="4de1596"
                        data-element_type="section"
                        data-settings='{"jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"d76abbc","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}]}'
                      >
                        <div
                          className="elementor-container elementor-column-gap-default"
                        >
                          <div
                            className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-37a77d8"
                            data-id="37a77d8"
                            data-element_type="column"
                          >
                            <div
                              className="elementor-widget-wrap elementor-element-populated"
                            >
                              <div
                                className="elementor-element elementor-element-9b35f17 elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-image-box"
                                data-id="9b35f17"
                                data-element_type="widget"
                                data-widget_type="image-box.default"
                              >
                                <div className="elementor-widget-container">
                                  <style>
                                    
                                  </style>
                                  <div className="elementor-image-box-wrapper">
                                    <figure className="elementor-image-box-img">
                                      <img
                                        width="119"
                                        height="115"
                                        src="images/specialist.webp"
                                        className="attachment-full size-full"
                                        alt=""
                                        loading="lazy"
                                      />
                                    </figure>
                                    <div className="elementor-image-box-content">
                                      <h3 className="elementor-image-box-title">
                                        Specialist Services
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-505792c"
                            data-id="505792c"
                            data-element_type="column"
                          >
                            <div
                              className="elementor-widget-wrap elementor-element-populated"
                            >
                              <div
                                className="elementor-element elementor-element-5aae4eb elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-image-box"
                                data-id="5aae4eb"
                                data-element_type="widget"
                                data-widget_type="image-box.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-image-box-wrapper">
                                    <figure className="elementor-image-box-img">
                                      <img
                                        width="113"
                                        height="113"
                                        src="images/kit.webp"
                                        className="attachment-full size-full"
                                        alt=""
                                        loading="lazy"
                                      />
                                    </figure>
                                    <div className="elementor-image-box-content">
                                      <h3 className="elementor-image-box-title">
                                        Diagnostic Services
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-250fa25"
                            data-id="250fa25"
                            data-element_type="column"
                          >
                            <div
                              className="elementor-widget-wrap elementor-element-populated"
                            >
                              <div
                                className="elementor-element elementor-element-119fb28 elementor-position-top elementor-vertical-align-top elementor-widget elementor-widget-image-box"
                                data-id="119fb28"
                                data-element_type="widget"
                                data-widget_type="image-box.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-image-box-wrapper">
                                    <figure className="elementor-image-box-img">
                                      <img
                                        width="116"
                                        height="120"
                                        src="images/check.webp"
                                        className="attachment-full size-full"
                                        alt=""
                                        loading="lazy"
                                      />
                                    </figure>
                                    <div className="elementor-image-box-content">
                                      <h3 className="elementor-image-box-title">
                                        General Health Checks
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </section>
    )
}
export default Services;