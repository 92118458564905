const PrivacyHero = () => {
    return (
        <section
                className="elementor-section elementor-top-section elementor-element elementor-element-b30c47a elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
                data-id="b30c47a"
                data-element_type="section"
                data-settings='{"jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"55989cd","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}],"background_background":"classic","shape_divider_bottom":"curve","shape_divider_bottom_negative":"yes"}'
                style={{backgroundImage: "url('images/privacy.webp')"}}
              >
                <div className="elementor-background-overlay"></div>
                <div
                  className="elementor-shape elementor-shape-bottom"
                  data-negative="true"
                >
                  <svg
                    xmlns="//www.w3.org/2000/svg"
                    viewBox="0 0 1000 100"
                    preserveAspectRatio="none"
                  >
                    <path
                      className="elementor-shape-fill"
                      d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"
                    ></path>
                  </svg>
                </div>
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a22e19a"
                    data-id="a22e19a"
                    data-element_type="column"
                  >
                    <div
                      className="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        className="elementor-element elementor-element-2352426 elementor-widget elementor-widget-heading"
                        data-id="2352426"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <style>
                           
                          </style>
                          <h2
                            className="elementor-heading-title elementor-size-default"
                          >
                            Privacy Policy
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b535203 elementor-hidden-mobile"
                    data-id="b535203"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap"></div>
                  </div>
                </div>
              </section>
    )
}
export default PrivacyHero;