import React from 'react';
import ElementorContainer from '../components/ElementorContainer';
import FAQS from '../components/faq/faqs';
import FaqHero from '../components/faq/hero';
import Layout from '../components/Layout';

const FAQ = () => {
    return (
        <Layout title="Frequently Asked Questions">
                <ElementorContainer id="845">
                    <FaqHero/>
                    <FAQS/>
                </ElementorContainer>
        </Layout>
    )
}
export default FAQ;