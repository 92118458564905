import React from 'react';
import AboutDetails from '../components/about/about';
import AboutHero from '../components/about/hero';
import ElementorContainer from '../components/ElementorContainer';
import Layout from '../components/Layout';
import Presence from '../components/about/presence';

const About = () => {
    return (
        <Layout title="About Yondacare">
                <ElementorContainer id="152">
                    <AboutHero/>
                    <AboutDetails/>
                    <Presence/>
                </ElementorContainer>
        </Layout>
    )
}
export default About;