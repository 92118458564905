/* eslint-disable */
const Carousel = () => {
    return (
        <section
                className="elementor-section elementor-top-section elementor-element elementor-element-e44f29a elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                data-id="e44f29a"
                data-element_type="section"
                data-settings='{"jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"242106b","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}]}'
              >
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-64ced1f"
                    data-id="64ced1f"
                    data-element_type="column"
                  >
                    <div
                      className="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        className="elementor-element elementor-element-3c7d34c elementor-widget elementor-widget-prime-slider-general"
                        data-id="3c7d34c"
                        data-element_type="widget"
                        data-widget_type="prime-slider-general.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="bdt-prime-slider">
                            <div className="bdt-prime-slider-skin-general">
                              <div
                                className="bdt-position-relative bdt-visible-toggle"
                                bdt-slideshow='{"animation":"fade","ratio":"16:8","min-height":480,"autoplay":true,"autoplay-interval":7000,"pause-on-hover":false,"velocity":1,"finite":false}'
                                data-settings='{"id":"#bdt-3c7d34c","animation_status":"no"}'
                                id="bdt-3c7d34c"
                              >
                                <ul className="bdt-slideshow-items">
                                  <li
                                    className="bdt-slideshow-item bdt-flex bdt-flex-middle bdt-flex-center elementor-repeater-item-8f29f72"
                                  >
                                    <div
                                      className="bdt-position-cover bdt-animation-kenburns bdt-animation-reverse bdt-transform-origin-center-left"
                                    >
                                      <div
                                        className="bdt-ps-slide-img"
                                        style={{
                                          backgroundImage: 'url(images/YonDaCare-1-scaled.jpg)'
                                        }}
                                      ></div>
                                    </div>

                                    <div
                                      className="bdt-overlay-default bdt-position-cover"
                                    ></div>

                                    <div
                                      className="bdt-position-z-index bdt-position-large"
                                    >
                                      <div className="bdt-prime-slider-wrapper">
                                        <div className="bdt-prime-slider-content">
                                          <div className="bdt-prime-slider-desc">
                                            <div
                                              className="bdt-main-title"
                                              bdt-slideshow-parallax="x: 100,0,-20; opacity: 1,1,0"
                                            >
                                              <h1 className="bdt-title-tag">
                                                WELCOME TO YONDACARE
                                              </h1>
                                            </div>

                                            <div
                                              bdt-slideshow-parallax="x: 150,0,-30; opacity: 1,1,0"
                                            >
                                              <div className="bdt-btn-wrapper">
                                                <a
                                                  className="bdt-slide-btn"
                                                  href="/get-started"
                                                >
                                                  <span
                                                    className="bdt-prime-slider-button-wrapper"
                                                  >
                                                    <span
                                                      className="bdt-prime-slider-button-text bdt-flex bdt-flex-middle bdt-flex-inline"
                                                      >GET STARTED<span
                                                        className="bdt-slide-btn-icon"
                                                        ><svg
                                                          width="20"
                                                          height="20"
                                                          viewBox="0 0 20 20"
                                                          xmlns="//www.w3.org/2000/svg"
                                                          data-svg="arrow-right"
                                                        >
                                                          <polyline
                                                            fill="none"
                                                            stroke="#000"
                                                            points="10 5 15 9.5 10 14"
                                                          ></polyline>
                                                          <line
                                                            fill="none"
                                                            stroke="#000"
                                                            x1="4"
                                                            y1="9.5"
                                                            x2="15"
                                                            y2="9.5"
                                                          ></line></svg></span
                                                    ></span>
                                                  </span>
                                                </a>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            className="bdt-slider-excerpt"
                                            bdt-slideshow-parallax="y: 50,0,-10; opacity: 1,1,0"
                                          >
                                            <p>
                                              At YondaCare we believe every
                                              mother and child, teenager, adult
                                              and senior citizen should enjoy
                                              hassle-free, affordable access to
                                              health care services especially at
                                              the times when a health emergency
                                              or an accident occurs.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>

                                  <li
                                    className="bdt-slideshow-item bdt-flex bdt-flex-middle bdt-flex-center elementor-repeater-item-2ebe7ca"
                                  >
                                    <div
                                      className="bdt-position-cover bdt-animation-kenburns bdt-animation-reverse bdt-transform-origin-center-left"
                                    >
                                      <div
                                        className="bdt-ps-slide-img"
                                        style={{
                                            backgroundImage: 'url(images/YondaCare-b.jpg)'
                                        }}
                                      ></div>
                                    </div>

                                    <div
                                      className="bdt-overlay-default bdt-position-cover"
                                    ></div>

                                    <div
                                      className="bdt-position-z-index bdt-position-large"
                                    >
                                      <div className="bdt-prime-slider-wrapper">
                                        <div
                                          className="bdt-prime-slider-content bdt-prime-slider-content"
                                        >
                                          <div className="bdt-prime-slider-desc">
                                            <div
                                              className="bdt-main-title"
                                              bdt-slideshow-parallax="x: 100,0,-20; opacity: 1,1,0"
                                            >
                                              <h1 className="bdt-title-tag">
                                                WELCOME TO YONDACARE
                                              </h1>
                                            </div>

                                            <div
                                              bdt-slideshow-parallax="x: 150,0,-30; opacity: 1,1,0"
                                            >
                                              <div className="bdt-btn-wrapper">
                                                <a
                                                  className="bdt-slide-btn"
                                                  href="/get-started"
                                                >
                                                  <span
                                                    className="bdt-prime-slider-button-wrapper"
                                                  >
                                                    <span
                                                      className="bdt-prime-slider-button-text bdt-flex bdt-flex-middle bdt-flex-inline"
                                                      >GET STARTED<span
                                                        className="bdt-slide-btn-icon"
                                                        ><svg
                                                          width="20"
                                                          height="20"
                                                          viewBox="0 0 20 20"
                                                          xmlns="//www.w3.org/2000/svg"
                                                          data-svg="arrow-right"
                                                        >
                                                          <polyline
                                                            fill="none"
                                                            stroke="#000"
                                                            points="10 5 15 9.5 10 14"
                                                          ></polyline>
                                                          <line
                                                            fill="none"
                                                            stroke="#000"
                                                            x1="4"
                                                            y1="9.5"
                                                            x2="15"
                                                            y2="9.5"
                                                          ></line></svg></span
                                                    ></span>
                                                  </span>
                                                </a>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            className="bdt-slider-excerpt"
                                            bdt-slideshow-parallax="y: 50,0,-10; opacity: 1,1,0"
                                          >
                                            <p>
                                              At YondaCare we believe every
                                              mother and child, teenager, adult
                                              and senior citizen should enjoy
                                              hassle-free, affordable access to
                                              health care services especially at
                                              the times when a health emergency
                                              or an accident occurs.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>

                                  <li
                                    className="bdt-slideshow-item bdt-flex bdt-flex-middle bdt-flex-center elementor-repeater-item-25ae3fa"
                                  >
                                    <div
                                      className="bdt-position-cover bdt-animation-kenburns bdt-animation-reverse bdt-transform-origin-center-left"
                                    >
                                      <div
                                        className="bdt-ps-slide-img"
                                        style={{
                                            backgroundImage: 'url(images/YondaCare-c.jpg)'
                                        }}
                                      ></div>
                                    </div>

                                    <div
                                      className="bdt-overlay-default bdt-position-cover"
                                    ></div>

                                    <div
                                      className="bdt-position-z-index bdt-position-large"
                                    >
                                      <div className="bdt-prime-slider-wrapper">
                                        <div
                                          className="bdt-prime-slider-content bdt-prime-slider-content bdt-prime-slider-content"
                                        >
                                          <div className="bdt-prime-slider-desc">
                                            <div
                                              className="bdt-main-title"
                                              bdt-slideshow-parallax="x: 100,0,-20; opacity: 1,1,0"
                                            >
                                              <h1 className="bdt-title-tag">
                                                WELCOME TO YONDACARE
                                              </h1>
                                            </div>

                                            <div
                                              bdt-slideshow-parallax="x: 150,0,-30; opacity: 1,1,0"
                                            >
                                              <div className="bdt-btn-wrapper">
                                                <a
                                                  className="bdt-slide-btn"
                                                  href="/get-started"
                                                >
                                                  <span
                                                    className="bdt-prime-slider-button-wrapper"
                                                  >
                                                    <span
                                                      className="bdt-prime-slider-button-text bdt-flex bdt-flex-middle bdt-flex-inline"
                                                      >GET STARTED<span
                                                        className="bdt-slide-btn-icon"
                                                        ><svg
                                                          width="20"
                                                          height="20"
                                                          viewBox="0 0 20 20"
                                                          xmlns="//www.w3.org/2000/svg"
                                                          data-svg="arrow-right"
                                                        >
                                                          <polyline
                                                            fill="none"
                                                            stroke="#000"
                                                            points="10 5 15 9.5 10 14"
                                                          ></polyline>
                                                          <line
                                                            fill="none"
                                                            stroke="#000"
                                                            x1="4"
                                                            y1="9.5"
                                                            x2="15"
                                                            y2="9.5"
                                                          ></line></svg></span
                                                    ></span>
                                                  </span>
                                                </a>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            className="bdt-slider-excerpt"
                                            bdt-slideshow-parallax="y: 50,0,-10; opacity: 1,1,0"
                                          >
                                            <p>
                                              At YondaCare we believe every
                                              mother and child, teenager, adult
                                              and senior citizen should enjoy
                                              hassle-free, affordable access to
                                              health care services especially at
                                              the times when a health emergency
                                              or an accident occurs.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>

                                  <li
                                    className="bdt-slideshow-item bdt-flex bdt-flex-middle bdt-flex-center elementor-repeater-item-0ff36cd"
                                  >
                                    <div
                                      className="bdt-position-cover bdt-animation-kenburns bdt-animation-reverse bdt-transform-origin-center-left"
                                    >
                                      <div
                                        className="bdt-ps-slide-img"
                                        style={{
                                            backgroundImage: 'url(images/YondaCare-d.jpg)'
                                        }}
                                      ></div>
                                    </div>

                                    <div
                                      className="bdt-overlay-default bdt-position-cover"
                                    ></div>

                                    <div
                                      className="bdt-position-z-index bdt-position-large"
                                    >
                                      <div className="bdt-prime-slider-wrapper">
                                        <div
                                          className="bdt-prime-slider-content bdt-prime-slider-content bdt-prime-slider-content bdt-prime-slider-content"
                                        >
                                          <div className="bdt-prime-slider-desc">
                                            <div
                                              className="bdt-main-title"
                                              bdt-slideshow-parallax="x: 100,0,-20; opacity: 1,1,0"
                                            >
                                              <h1 className="bdt-title-tag">
                                                WELCOME TO YONDACARE
                                              </h1>
                                            </div>

                                            <div
                                              bdt-slideshow-parallax="x: 150,0,-30; opacity: 1,1,0"
                                            >
                                              <div className="bdt-btn-wrapper">
                                                <a
                                                  className="bdt-slide-btn"
                                                  href="/get-started"
                                                >
                                                  <span
                                                    className="bdt-prime-slider-button-wrapper"
                                                  >
                                                    <span
                                                      className="bdt-prime-slider-button-text bdt-flex bdt-flex-middle bdt-flex-inline"
                                                      >GET STARTED<span
                                                        className="bdt-slide-btn-icon"
                                                        ><svg
                                                          width="20"
                                                          height="20"
                                                          viewBox="0 0 20 20"
                                                          xmlns="//www.w3.org/2000/svg"
                                                          data-svg="arrow-right"
                                                        >
                                                          <polyline
                                                            fill="none"
                                                            stroke="#000"
                                                            points="10 5 15 9.5 10 14"
                                                          ></polyline>
                                                          <line
                                                            fill="none"
                                                            stroke="#000"
                                                            x1="4"
                                                            y1="9.5"
                                                            x2="15"
                                                            y2="9.5"
                                                          ></line></svg></span
                                                    ></span>
                                                  </span>
                                                </a>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            className="bdt-slider-excerpt"
                                            bdt-slideshow-parallax="y: 50,0,-10; opacity: 1,1,0"
                                          >
                                            <p>
                                              At YondaCare we believe every
                                              mother and child, teenager, adult
                                              and senior citizen should enjoy
                                              hassle-free, affordable access to
                                              health care services especially at
                                              the times when a health emergency
                                              or an accident occurs.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>

                                <div>
                                  
                                  <a
                                    className="bdt-position-bottom-right bdt-prime-slider-previous"
                                    href="#"
                                    bdt-slidenav-previous=""
                                    bdt-slideshow-item="previous"
                                  ></a>
                                  <a
                                    className="bdt-position-bottom-right bdt-prime-slider-next"
                                    href="#"
                                    bdt-slidenav-next=""
                                    bdt-slideshow-item="next"
                                  ></a>
                                </div>

                                <div>
                                  <ul
                                    className="bdt-slideshow-nav bdt-dotnav bdt-margin-large bdt-position-bottom-left bdt-text-center"
                                  ></ul>
                                </div>
                              </div>

                              <div className="bdt-scroll-down-wrapper">
                                <button
                                  className="bdt-scroll-down"
                                  data-settings="[]"
                                  data-selector="#my-header"
                                >
                                  <span
                                    bdt-scrollspy="cls: bdt-animation-slide-right; repeat: true"
                                    className="bdt-prime-slider-button-wrapper bdt-scroll-down-content-wrapper"
                                  >
                                    <span className="bdt-scroll-icon">
                                      <svg
                                        version="1.1"
                                        id="Capa_1"
                                        xmlns="//www.w3.org/2000/svg"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 64 64"
                                        style={{enableBackground: 'new 0 0 64 64'}}
                                      >
                                        <g>
                                          <g>
                                            <polygon
                                              points="31,0 31,60.586 23.707,53.293 22.293,54.854 31.293,64 32.707,64 41.707,54.854 40.293,53.366 33,60.586 33,0 "
                                            ></polygon>
                                          </g>
                                        </g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                      </svg>
                                    </span>
                                    <span
                                      className="bdt-prime-slider-button-text bdt-flex bdt-flex-middle bdt-flex-inline bdt-scroll-down-text"
                                      >Scroll Down</span
                                    >
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>

                          <div
                            className="bdt-offcanvas"
                            id="bdt-offcanvas-3c7d34c"
                            data-settings='{"id":"bdt-offcanvas-3c7d34c","layout":"default"}'
                            bdt-offcanvas="mode: slide;"
                          >
                            <div className="bdt-offcanvas-bar">
                              <button
                                className="bdt-offcanvas-close"
                                type="button"
                                bdt-close=""
                              ></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
        </section>
    )
}
export default Carousel;