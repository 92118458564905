const benefits = () => {
    return (
        <>
            <section
                className="elementor-section elementor-top-section elementor-element elementor-element-bc39131 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="bc39131"
                data-element_type="section"
                data-settings='{"jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"d2ff10f","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}]}'
              >
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a6dda18"
                    data-id="a6dda18"
                    data-element_type="column"
                  >
                    <div
                      className="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        className="elementor-element elementor-element-c2d387a elementor-widget elementor-widget-heading"
                        data-id="c2d387a"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h2
                            className="elementor-heading-title elementor-size-default"
                          >
                            Our Complete Benefits
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-ffea8e9 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="ffea8e9"
                data-element_type="section"
                data-settings='{"jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"d7911e6","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}]}'
              >
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-dcc0252"
                    data-id="dcc0252"
                    data-element_type="column"
                  >
                    <div
                      className="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        className="elementor-element elementor-element-d872a9c elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                        data-id="d872a9c"
                        data-element_type="widget"
                        data-widget_type="icon-list.default"
                      >
                        <div className="elementor-widget-container">
                          <style>
                            
                          </style>
                          <ul className="elementor-icon-list-items">
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="far fa-dot-circle"
                                ></i>
                              </span>
                              <span className="elementor-icon-list-text"
                                >Primary Dental Care - Examination</span
                              >
                            </li>
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="far fa-dot-circle"
                                ></i>
                              </span>
                              <span className="elementor-icon-list-text"
                                >Primary Eye Care - Examination</span
                              >
                            </li>
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="far fa-dot-circle"
                                ></i>
                              </span>
                              <span className="elementor-icon-list-text"
                                >Treatment of ENT diseases</span
                              >
                            </li>
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="far fa-dot-circle"
                                ></i>
                              </span>
                              <span className="elementor-icon-list-text"
                                >Physiotherapy Sessions (Up to 3 Sessions)</span
                              >
                            </li>
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="far fa-dot-circle"
                                ></i>
                              </span>
                              <span className="elementor-icon-list-text"
                                >Prescribed Physiotherapeutic appliances
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-d51b4f3"
                    data-id="d51b4f3"
                    data-element_type="column"
                  >
                    <div
                      className="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        className="elementor-element elementor-element-aaa93fa elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                        data-id="aaa93fa"
                        data-element_type="widget"
                        data-widget_type="icon-list.default"
                      >
                        <div className="elementor-widget-container">
                          <ul className="elementor-icon-list-items">
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="far fa-dot-circle"
                                ></i>
                              </span>
                              <span className="elementor-icon-list-text"
                                >Basic Radiological studies e.g., X-ray</span
                              >
                            </li>
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="far fa-dot-circle"
                                ></i>
                              </span>
                              <span className="elementor-icon-list-text"
                                >Laboratory Services- Histopathology</span
                              >
                            </li>
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="far fa-dot-circle"
                                ></i>
                              </span>
                              <span className="elementor-icon-list-text"
                                >Cardiac Diagnostics: ECG</span
                              >
                            </li>
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="far fa-dot-circle"
                                ></i>
                              </span>
                              <span className="elementor-icon-list-text"
                                >24hour ambulatory ECG</span
                              >
                            </li>
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="far fa-dot-circle"
                                ></i>
                              </span>
                              <span className="elementor-icon-list-text"
                                >Contrast X-ray</span
                              >
                            </li>
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="far fa-dot-circle"
                                ></i>
                              </span>
                              <span className="elementor-icon-list-text"
                                >Ultrasonography
                              </span>
                            </li>
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="far fa-dot-circle"
                                ></i>
                              </span>
                              <span className="elementor-icon-list-text"
                                >Hematological investigations</span
                              >
                            </li>
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="far fa-dot-circle"
                                ></i>
                              </span>
                              <span className="elementor-icon-list-text"
                                >Echocardiography</span
                              >
                            </li>
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="far fa-dot-circle"
                                ></i>
                              </span>
                              <span className="elementor-icon-list-text"
                                >Stress Tests</span
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-264f47e"
                    data-id="264f47e"
                    data-element_type="column"
                  >
                    <div
                      className="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        className="elementor-element elementor-element-b5ae24b elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                        data-id="b5ae24b"
                        data-element_type="widget"
                        data-widget_type="icon-list.default"
                      >
                        <div className="elementor-widget-container">
                          <ul className="elementor-icon-list-items">
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="far fa-dot-circle"
                                ></i>
                              </span>
                              <span className="elementor-icon-list-text"
                                >On-site Health Checks</span
                              >
                            </li>
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="far fa-dot-circle"
                                ></i>
                              </span>
                              <span className="elementor-icon-list-text"
                                >Health Talks/ Education forum</span
                              >
                            </li>
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="far fa-dot-circle"
                                ></i>
                              </span>
                              <span className="elementor-icon-list-text"
                                >HIV/AIDS- Diagnosis
                              </span>
                            </li>
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="far fa-dot-circle"
                                ></i>
                              </span>
                              <span className="elementor-icon-list-text"
                                >Treatment at free specialists</span
                              >
                            </li>
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="far fa-dot-circle"
                                ></i>
                              </span>
                              <span className="elementor-icon-list-text"
                                >Hepatitis B</span
                              >
                            </li>
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="far fa-dot-circle"
                                ></i>
                              </span>
                              <span className="elementor-icon-list-text">DPT</span>
                            </li>
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="far fa-dot-circle"
                                ></i>
                              </span>
                              <span className="elementor-icon-list-text"
                                >Vitamin A</span
                              >
                            </li>
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="far fa-dot-circle"
                                ></i>
                              </span>
                              <span className="elementor-icon-list-text"
                                >Measles</span
                              >
                            </li>
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="far fa-dot-circle"
                                ></i>
                              </span>
                              <span className="elementor-icon-list-text"
                                >Yellow Fever</span
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
        </>
    )
}
export default benefits;