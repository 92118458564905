import { Link } from "react-router-dom";
const HospitalHero = () => {
    return (
        <section
                className="elementor-section elementor-top-section elementor-element elementor-element-8622c7b elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
                data-id="8622c7b"
                data-element_type="section"
                data-settings='{"jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"55989cd","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}],"background_background":"classic"}'
                style={{backgroundImage: "url('images/hospitals.webp')"}}
              >
                <div className="elementor-background-overlay"></div>
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-e61cef2"
                    data-id="e61cef2"
                    data-element_type="column"
                  >
                    <div
                      className="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        className="elementor-element elementor-element-3d7bd74 elementor-widget elementor-widget-heading"
                        data-id="3d7bd74"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <style>
                            
                          </style>
                          <h2
                            className="elementor-heading-title elementor-size-default"
                          >
                            Affordable Quality Health Insurance Nationwide
                          </h2>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-c19fe91 elementor-widget elementor-widget-text-editor"
                        data-id="c19fe91"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div className="elementor-widget-container">
                          <style>
                            
                          </style>
                          <p>
                            Sign-up to YondaCare plan at N1500 monthly and find
                            the right hospital from our 750+ hospitals
                            nationwide
                          </p>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-61a72db elementor-widget elementor-widget-button"
                        data-id="61a72db"
                        data-element_type="widget"
                        data-widget_type="button.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-button-wrapper">
                            <Link
                              to="/get-started"
                              className="elementor-button-link elementor-button elementor-size-sm"
                              role="button"
                            >
                              <span className="elementor-button-content-wrapper">
                                <span className="elementor-button-text"
                                  >GET STARTED</span
                                >
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3a5f028 elementor-hidden-mobile"
                    data-id="3a5f028"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap"></div>
                  </div>
                </div>
              </section>
    )
}
export default HospitalHero;