const FaqHero  = () => {
    return (
        <section
        className="elementor-section elementor-top-section elementor-element elementor-element-86c9fae elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
        data-id="86c9fae"
        data-element_type="section"
        data-settings='{"jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"55989cd","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}],"background_background":"classic"}'
        style={{backgroundImage: "url('images/fa.webp')"}}
      >
        <div className="elementor-background-overlay"></div>
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3df3047"
            data-id="3df3047"
            data-element_type="column"
          >
            <div
              className="elementor-widget-wrap elementor-element-populated"
            >
              <div
                className="elementor-element elementor-element-1f22854 elementor-widget elementor-widget-heading"
                data-id="1f22854"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <style>
                    
                  </style>
                  <h2
                    className="elementor-heading-title elementor-size-default"
                  >
                    FAQs
                  </h2>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-b043af6 elementor-widget elementor-widget-text-editor"
                data-id="b043af6"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div className="elementor-widget-container">
                  <style>
                    
                  </style>
                  <p>
                    Find out all you need to know about our YondaCare
                    Health Insurance Plan
                  </p>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-ea6d6ed elementor-widget elementor-widget-button"
                data-id="ea6d6ed"
                data-element_type="widget"
                data-widget_type="button.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-button-wrapper">
                    <a
                      href="https://ibez.elementorsolution.com/get-started/"
                      className="elementor-button-link elementor-button elementor-size-sm"
                      role="button"
                    >
                      <span className="elementor-button-content-wrapper">
                        <span className="elementor-button-text"
                          >GET STARTED</span
                        >
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-1a3c4c7 elementor-hidden-mobile"
            data-id="1a3c4c7"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap"></div>
          </div>
        </div>
      </section>
    )
}
export default FaqHero;