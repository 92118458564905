const Policies = () => {
    return (
        <section
                className="elementor-section elementor-top-section elementor-element elementor-element-8f7aa1e elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="8f7aa1e"
                data-element_type="section"
                data-settings='{"jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"49f0cfb","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}],"background_background":"classic"}'
              >
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-712bd75"
                    data-id="712bd75"
                    data-element_type="column"
                  >
                    <div
                      className="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        className="elementor-element elementor-element-6a4e498 elementor-widget elementor-widget-text-editor"
                        data-id="6a4e498"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div className="elementor-widget-container">
                          <style>
                            
                          </style>
                          <p className="c3">
                            <span className="c7 c5"
                              >We are committed to protecting your personal
                              information, therefore, your personal information
                              will be treated as strictly confidential. If you
                              want to use services via our Website, collection
                              and processing of personal data could become
                              necessary. By using our sites, you agree to the
                              collection, use and disclosure of your personal
                              information. Occasionally we may need to vary our
                              privacy policy. You should check our Website from
                              time to time to view our most up to date privacy
                              policy.</span
                            >
                          </p>
                          <h2 id="h.ynz7t0czcrrm" className="c10">
                            <span className="c0"
                              >We collect personal information directly from you
                              when you:</span
                            >
                          </h2>
                          <ul className="c1 lst-kix_pkb0zq6lu1of-0 start">
                            <li className="c4 li-bullet-0">
                              <span className="c7 c5"
                                >Chat with any of our personnel on our
                                website</span
                              >
                            </li>
                            <li className="c4 li-bullet-0">
                              <span className="c5"
                                >Purchase a health insurance plan (“</span
                              ><span className="c5 c6">Plan</span
                              ><span className="c5 c7">”);</span>
                            </li>
                            <li className="c4 li-bullet-0">
                              <span className="c7 c5">Make a claim; or</span>
                            </li>
                            <li className="c4 li-bullet-0">
                              <span className="c7 c5"
                                >Contact us by telephone, email, Social Media
                                posts to make a general enquiry or discuss your
                                Plan or claim.</span
                              >
                            </li>
                          </ul>
                          <p className="c10">
                            <span className="c7 c5"
                              >If you give us personal information about another
                              person who is to be included on your Plan, you
                              should only do so if you have their consent. You
                              must make sure that they have seen a copy of this
                              privacy notice and are comfortable with you giving
                              us their information.</span
                            >
                          </p>
                          <h2 id="h.hexij81g1okg" className="c10">
                            <span className="c0"
                              >Why do we collect personal information?</span
                            >
                          </h2>
                          <p className="c10">
                            <span className="c7 c5"
                              >The main reason we collect personal information
                              is to enable us to provide you with the Plan you
                              intend to purchase or have purchased and to make
                              sure we administer it correctly and efficiently.
                              Steps are taken to ensure the data we hold is
                              accurate, kept up to date and not kept for longer
                              than is necessary. Measures are taken to safeguard
                              against unauthorised or unlawful processing and
                              accidental loss or destruction or damage to the
                              data.</span
                            >
                          </p>
                          <p className="c3">
                            <span className="c7 c5"
                              >However, if you believe the information we hold
                              about you is factually incorrect, you can ask us
                              to amend it. In certain circumstances, you can ask
                              us to stop using your personal information – for
                              example if you think the personal information we
                              hold may be inaccurate or you think we no longer
                              need to process it.</span
                            >
                          </p>
                          <h2 id="h.xkqskz3j3y9o" className="c10">
                            <span className="c0"
                              >What do we use your information for?</span
                            >
                          </h2>
                          <p className="c3">
                            <span className="c7 c5"
                              >Any information we collect from you may be used
                              in one of the following ways:</span
                            >
                          </p>
                          <ul className="c1 lst-kix_v9tuio735x4h-0 start">
                            <li className="c4 li-bullet-0">
                              <span className="c7 c5"
                                >To personalize your experience (your
                                information helps us to better respond to your
                                personal needs).</span
                              >
                            </li>
                            <li className="c4 li-bullet-0">
                              <span className="c7 c5"
                                >To process transactions.</span
                              >
                            </li>
                            <li className="c4 li-bullet-0">
                              <span className="c7 c5"
                                >To improve our website (we continually strive
                                to improve our website offerings based on the
                                information and feedback we receive from
                                you).</span
                              >
                            </li>
                            <li className="c4 li-bullet-0">
                              <span className="c7 c5"
                                >To improve customer service.</span
                              >
                            </li>
                            <li className="c4 li-bullet-0">
                              <span className="c7 c5"
                                >To send periodic emails. The email address you
                                provide may be used to send you information and
                                updates pertaining to our Plans and services, in
                                addition to receiving occasional company news,
                                updates, related product or service information
                                etc. If at any time you would like to
                                unsubscribe from receiving future emails, we
                                include detailed unsubscribe instructions at the
                                bottom of each email.</span
                              >
                            </li>
                            <li className="c4 li-bullet-0">
                              <span className="c7 c5"
                                >To administer a contest, promotion, survey or
                                other site feature.</span
                              >
                            </li>
                          </ul>
                          <h2 id="h.srefpt2ik44s" className="c10">
                            <span className="c0"
                              >How do we process your information?</span
                            >
                          </h2>
                          <p className="c3">
                            <span className="c7 c5"
                              >We process your personal information for the
                              purposes set out in this privacy notice. We
                              normally process standard personal information if
                              this is necessary to provide the services set out
                              in a contract, it is in our or a third party’s
                              legitimate interests or it is required or allowed
                              by any law that applies.</span
                            >
                          </p>
                          <h2 id="h.xik2hmjkkur5" className="c10">
                            <span className="c0"
                              >Do we disclose your information to third
                              parties?</span
                            >
                          </h2>
                          <p className="c10">
                            <span className="c7 c5"
                              >We do not sell, trade, or otherwise transfer to
                              third parties, your personally identifiable
                              information. This does not include trusted third
                              parties who assist us in operating our website,
                              conducting our business, or servicing you, so long
                              as those parties agree to keep this information
                              confidential. We may also release your information
                              when we believe release is appropriate to comply
                              with the law, enforce our site policies, or
                              protect ours or others rights, property or safety.
                              However, non-personally identifiable visitor
                              information may be provided to other parties for
                              marketing, advertising or other uses. We also
                              disclose information where:</span
                            >
                          </p>
                          <ul className="c1 lst-kix_1fhpim5sskd9-0 start">
                            <li className="c4 li-bullet-0">
                              <span className="c7 c5"
                                >It is necessary to establish, make or defend
                                legal claims;</span
                              >
                            </li>
                            <li className="c4 li-bullet-0">
                              <span className="c7 c5"
                                >It is necessary for the purposes of preventing
                                or detecting an unlawful act in circumstances
                                where we must carry out checks without your
                                permission so as not to affect the outcome of
                                those checks;</span
                              >
                            </li>
                            <li className="c4 li-bullet-0">
                              <span className="c7 c5"
                                >It is necessary for a purpose designed to
                                protect the public against dishonesty,
                                malpractice or other seriously improper
                                behaviour;</span
                              >
                            </li>
                            <li className="c4 li-bullet-0">
                              <span className="c7 c5"
                                >It is in the public interest, in line with any
                                laws that apply;</span
                              >
                            </li>
                            <li className="c4 li-bullet-0">
                              <span className="c7 c5"
                                >It is information that you have made public;
                                or</span
                              >
                            </li>
                            <li className="c4 li-bullet-0">
                              <span className="c7 c5"
                                >We have your permission.</span
                              >
                            </li>
                          </ul>
                          <h2 id="h.olg9rl10e18z" className="c10">
                            <span className="c0">Your consent</span>
                          </h2>
                          <p className="c3">
                            <span className="c7 c5"
                              >By using our Website, you consent to our privacy
                              policy.</span
                            >
                          </p>
                          <h2 id="h.ueih23pa16yw" className="c10">
                            <span className="c0"
                              >Automatically Collected Information</span
                            >
                          </h2>
                          <p className="c3">
                            <span className="c7 c5"
                              >When you use our Website, we automatically
                              collect certain computer information by the
                              interaction of your mobile phone or web browser
                              with our Website. Such information is typically
                              considered non-personal information. We also
                              collect the following:</span
                            >
                          </p>
                          <ul className="c1 lst-kix_80kur0myw2ig-0 start">
                            <li className="c4 li-bullet-0">
                              <span className="c12">Cookies<br /></span
                              ><span className="c7 c5"
                                >Our Website uses cookies to identify the areas
                                of our Website that you have visited. We use
                                cookies to personalize the Content that you see
                                on our Website. We do not place personally
                                identifiable information in Cookies. Most web
                                browsers can be set to disable the use of
                                Cookies. Please note that if you disable
                                Cookies, you may not be able to access
                                functionality on our Website correctly or at
                                all.</span
                              >
                            </li>
                          </ul>
                          <h2 id="h.6d4l873jo338" className="c10">
                            <span className="c0">Analytics</span>
                          </h2>
                          <p className="c3">
                            <span className="c5"
                              >Google Analytics is a web analytics service
                              offered by Google that tracks and reports website
                              traffic. Google uses the data collected to track
                              and monitor the use of our service. This data is
                              shared with other Google services. Google may use
                              the collected data to contextualize and
                              personalize the ads of its own advertising
                              network. You can opt-out of having made your
                              activity on the service available to Google
                              Analytics by installing the Google Analytics
                              opt-out browser add-on. The add-on prevents the
                              Google Analytics JavaScript from sharing
                              information with Google Analytics about visits
                              activity. For more information on the privacy
                              practices of Google, please visit the Google
                              Privacy &amp; Terms web page </span
                            ><span className="c15"
                              ><a
                                className="c11"
                                href="https://www.google.com/url?q=https://dev-yonda.ajocard.com/tos&amp;sa=D&amp;source=editors&amp;ust=1643006245221472&amp;usg=AOvVaw3lLR5R2TCFOtHOSmWEx8DI"
                                >here</a
                              ></span
                            >
                          </p>
                          <h2 id="h.a16kzu39rqud" className="c10">
                            <span className="c0">Payments processors</span>
                          </h2>
                          <p className="c3">
                            <span className="c7 c5"
                              >We provide paid products and/or services on our
                              Website. In that case, we use third-party services
                              for payment processing (e.g. payment processors).
                              We will not store or collect your payment card
                              details. That information is provided directly to
                              our third-party payment processors whose use of
                              your personal information is governed by their
                              Privacy Policy.</span
                            >
                          </p>
                          <p className="c13">&nbsp;</p>
                          <p className="c10">
                            <span className="c5"
                              >Questions, comments and requests regarding our
                              privacy policy should be sent to </span
                            ><span className="c2">hello@yondacare.com</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
    )
}
export default Policies;