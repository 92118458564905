import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import variables from './variables';

const getReference = () => {
  let text = "";
  let sub = "";
  let possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-.=";

  for (let i = 0; i < 6; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  for (let i = 0; i < 6; i++) {
    sub += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return `YONDA-${text}-INS-CSV-${sub}`;
};

function FileUpload({
  data,
  baseAPIUrl,
  setData,
  dataProperty,
  contentType,
}) {
  const [uploading, setUploading] = useState(false);
  const [fileName, setFileName] = useState('');
  const uploadFile =  (event) => {
    setUploading(true);
    if (event.target.files.length === 0) return;
    const media = event.target.files[0];
    const filename = media.name;
    const filesize = media.size / 1024 / 1024;
    let last_dot = filename.lastIndexOf(".");
    let ext = filename.slice(last_dot + 1);
    if (filesize > 20) {
      toast.error("Maximum File size should be 20MB");
      setUploading(false);
    } else if (ext !== "csv") {
      toast.error("File extension should only be csv");
      setUploading(false);
    } else {
      let bucketKey = `${getReference()}.${ext}`;
      let file = event.target.files[0];
      fetch(`${baseAPIUrl}/public/upload/${bucketKey}?contentType=${contentType}`)
        .then((response) => response.json())
        .then((result) => {
          var options = {
            headers: {
              "Content-Type": contentType,
            },
          };
          axios
            .put(result.response, file, options)
            .then(() => {
              // fetch usable url
              fetch(`${baseAPIUrl}/public/file/${bucketKey}`)
                .then((rr) => rr.json())
                .then((rr) => {
                  setData({
                    ...data,
                    url:rr.response,
                  });
                  setFileName(filename);
                  setUploading(false);
                })
            })
            .catch((err) => {
              setUploading(false);
              if (err?.message) {
                toast.error(err?.message || err?.errors[0].message);
              }
            });
        })
        .catch((err) => {
          setUploading(false);
          if (err?.message) {
            toast.error(err?.message || err?.errors[0].message);
          }
        });
    }
  };

  const previewClasses = ["img-preview", "border-2 border-gray-300 "].join(" ");

  return (
    <>
      <div>
        <div className={previewClasses} >
          <input
            required
            onChange={(e) => {
              uploadFile(e);
            }}
            type="file"
            className="absolute top-0 right-0 left-0 opacity-0 h-full"
            style={{ width: 200, zIndex: 99 }}
          />
          {!uploading && !fileName && (
            <label className="img-label" htmlFor="hmo">
              Click to upload
            </label>
          )}

          {!uploading && fileName && (
            <label className="img-label" htmlFor="hmo">
              {fileName}
            </label>
          )}

          {uploading && <span>Loading...</span>}
        </div>
        <style>
          {`
            .img-input {
              width: 200px;
              height: 200px;
              opacity: 0;
              position: absolute;
              overflow: hidden;
              z-index: -1;
            }

            .img-label {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }

            .img-preview {
              position: relative;
              width: 200px;
              height: 200px;
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          `}
        </style>
      </div>
      <br />
    </>
  );
}


const Uploader = () => {
  const baseAPIUrl = variables.API_BASE_URL;
  const [data, setData] = useState({
    type: 'yondacare',
    fileType: 'csv',
    url: '',
    userID: '',
    sending: false,
  });

  const send = async () => {    
    try {
      setData({ ...data, sending: true });
      toast.loading('loading....');
      await axios.post(`${baseAPIUrl}/importer`, {
        type: data.type,
        fileType: data.fileType,
        url: data.url,
      }, {
        headers: {
          'X-AjoCard-User': data.userID,
        }
      });
      toast.dismiss();
      toast.success('task queued for processing');
      setData({ ...data, sending: false });
    } catch (err) { 
        toast.dismiss();  
      toast.error(`an error occurred: ${err?.response?.data?.message || err?.message}`);
      setData({ ...data, sending: false });
    }
  };

  return (
    <main>
      <div className="bg-white py-8">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <section className="grid-flow-col grid-rows-2 grid-cols gap-2 p-4 w-5/6 m-auto justify-center">
          <div className="bg-white shadow-lg border p-8 text-dark col-12 w-5/6">
            <h4 className="text-left text-2xl font-bold pb-3">CSV Uploader</h4>
            <div>
              <FileUpload
                data={data}
                dataProperty="url"
                contentType="text/csv"
                baseAPIUrl={baseAPIUrl}
                setData={setData}
              />
            </div>
            <div>
              <label htmlFor="firstName" className="block font-bold text-gray-700">
                <p className="text-md font-medium ">User ID</p>
                <input
                  required
                  onChange={(e) => {
                    setData({ ...data, userID: e.target.value });
                  }}
                  value={data.userID}
                  type="text"
                  name="userID"
                  id="userID"
                  autoComplete="user id"
                  className="block w-full px-0.5 mt-2 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                />
              </label>
            </div>
            <div className="px-4 py-3 text-right flex justify-center sm:px-6">
              <button
                onClick={send}
                disabled={data.sending || !data.userID || !data.url}
                type="submit"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Submit
              </button>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default Uploader;