const HospitalList = () => {
  return (
    <>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-a60fc0a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="a60fc0a"
        data-element_type="section"
        data-settings='{"jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"077118c","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}],"background_background":"classic"}'
      >
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b1049a7"
            data-id="b1049a7"
            data-element_type="column"
          >
            <div
              className="elementor-widget-wrap elementor-element-populated"
            >
              <div
                className="elementor-element elementor-element-2a7bf18 elementor-widget elementor-widget-heading"
                data-id="2a7bf18"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h2
                    className="elementor-heading-title elementor-size-default"
                  >
                    List of Hospitals
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-5a001c7 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="5a001c7"
        data-element_type="section"
        data-settings='{"jet_parallax_layout_list":[{"jet_parallax_layout_image":{"url":"","id":""},"_id":"e7d2fea","jet_parallax_layout_image_tablet":{"url":"","id":""},"jet_parallax_layout_image_mobile":{"url":"","id":""},"jet_parallax_layout_speed":{"unit":"%","size":50,"sizes":[]},"jet_parallax_layout_type":"scroll","jet_parallax_layout_direction":null,"jet_parallax_layout_fx_direction":null,"jet_parallax_layout_z_index":"","jet_parallax_layout_bg_x":50,"jet_parallax_layout_bg_x_tablet":"","jet_parallax_layout_bg_x_mobile":"","jet_parallax_layout_bg_y":50,"jet_parallax_layout_bg_y_tablet":"","jet_parallax_layout_bg_y_mobile":"","jet_parallax_layout_bg_size":"auto","jet_parallax_layout_bg_size_tablet":"","jet_parallax_layout_bg_size_mobile":"","jet_parallax_layout_animation_prop":"transform","jet_parallax_layout_on":["desktop","tablet"]}],"background_background":"classic"}'
      >
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-548e5ee"
            data-id="548e5ee"
            data-element_type="column"
          >
            <div
              className="elementor-widget-wrap elementor-element-populated"
            >
              <div
                className="elementor-element elementor-element-42b65a5 elementor-widget elementor-widget-html"
                data-id="42b65a5"
                data-element_type="widget"
                data-widget_type="html.default"
              >
                <div className="elementor-widget-container">
                  <iframe
                    title="hospital list"
                    className="h-screen mx-auto w-11/12"
                    src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQEcVAOse8SnHPMOM2V_3xbrdwl_84Urcr83OOT3Kw4T5ODu7RKEW2XPdxaKnK-HodPg7UaxTQH8sQm/pubhtml?widget=true&amp;headers=false"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default HospitalList;