import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from './pages';
import Benefits from './pages/benefits';
import Hospitals from './pages/hospitals';
import About from './pages/about';
import Terms from './pages/terms';
import GetStarted from './pages/getStarted';
import { Header } from './components/Header';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import PrivacyPolicy from './pages/privacy';
import FAQ from './pages/faq';
import IdCard from './pages/idCard';
import UploadPage from './pages/upload';
import { ValidatePolicy } from './pages/validatePolicy';

function App() {
  return (
    <>
    <BrowserRouter>
    <Header/>
    <Routes>
      <Route path="/" element={<LandingPage/>}/>
      <Route path="/index" element={<LandingPage/>}/>
      <Route path="/benefits" element={<Benefits/>}/>
      <Route path="/hospitals" element={<Hospitals/>}/>
      <Route path="/about" element={<About/>}/>
      <Route path="/terms-of-use" element={<Terms/>}/>
      <Route path="/get-started" element={<GetStarted/>}/>
      <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
      <Route path="/idcard/:userID" element={<IdCard/>}/>
      <Route path="/uploader" element={<UploadPage/>}/>
      <Route path="/faqs" element={<FAQ/>}/>
      <Route path="/validate-policy" element={<ValidatePolicy />}/>
    </Routes>
    </BrowserRouter>
    <ToastContainer/>
    </>
  );
}

export default App;
