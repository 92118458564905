import React, { useEffect } from "react";
import ElementorContainer from "../components/ElementorContainer";
// import EnjoyYonda from "../components/Enjoy";
import Footer from "../components/Footer";
import Socials from "../components/Socials";

const Layout = ({ children, title }) => {
  document.title = title;
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <main id="main" className="site-main clr" role="main">
        {children}
        <ElementorContainer id="38" classes="elementor-location-footer">
          {/* TODO: Restore when insurance is live again. */}
          {/* <EnjoyYonda /> */}
          <Socials />
          <Footer />
        </ElementorContainer>
      </main>
    </>
  );
};
export default Layout;
