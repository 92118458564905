/* eslint-disable */
const Socials = () => {
    return (
        <section
              className="elementor-section elementor-top-section elementor-element elementor-element-a8c0da2 elementor-section-content-middle elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="a8c0da2"
              data-element_type="section"
              data-settings='{"stretch_section":"section-stretched","jet_parallax_layout_list":[],"background_background":"classic"}'
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-ddc56e0"
                  data-id="ddc56e0"
                  data-element_type="column"
                >
                  <div
                    className="elementor-widget-wrap elementor-element-populated"
                  >
                    <div
                      className="elementor-element elementor-element-c7a7d16 elementor-widget elementor-widget-heading"
                      data-id="c7a7d16"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h2
                          className="elementor-heading-title elementor-size-default"
                        >
                          Let's Get Social
                        </h2>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-d6e3753 elementor-widget elementor-widget-heading"
                      data-id="d6e3753"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h2
                          className="elementor-heading-title elementor-size-default"
                        >
                          We are here for you
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3fec4a7"
                  data-id="3fec4a7"
                  data-element_type="column"
                >
                  <div
                    className="elementor-widget-wrap elementor-element-populated"
                  >
                    <section
                      className="elementor-section elementor-inner-section elementor-element elementor-element-3b58634 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-id="3b58634"
                      data-element_type="section"
                      data-settings='{"jet_parallax_layout_list":[]}'
                    >
                      <div
                        className="elementor-container elementor-column-gap-default"
                      >
                        <div
                          className="make-column-clickable-elementor elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6ed1e60"
                          style={{cursor: "pointer"}}
                          data-column-clickable="#"
                          data-column-clickable-blank="_self"
                          data-id="6ed1e60"
                          data-element_type="column"
                        >
                          <div
                            className="elementor-widget-wrap elementor-element-populated"
                          >
                            <div
                              className="elementor-element elementor-element-2859e45 elementor-view-default elementor-widget elementor-widget-icon"
                              data-id="2859e45"
                              data-element_type="widget"
                              data-widget_type="icon.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-icon-wrapper">
                                  <div className="elementor-icon">
                                   <a aria-hidden="true" className="fab fa-facebook-f" href="https://www.facebook.com/yonda.carehealth" target="_blank" rel="noreferrer">
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-5edfe01 elementor-widget elementor-widget-heading"
                              data-id="5edfe01"
                              data-element_type="widget"
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <h2
                                  className="elementor-heading-title elementor-size-default"
                                >
                                  Facebook
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="make-column-clickable-elementor elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-dea6278"
                          style={{cursor: "pointer"}}
                          data-column-clickable="#"
                          data-column-clickable-blank="_self"
                          data-id="dea6278"
                          data-element_type="column"
                        >
                          <div
                            className="elementor-widget-wrap elementor-element-populated"
                          >
                            <div
                              className="elementor-element elementor-element-a96400a elementor-view-default elementor-widget elementor-widget-icon"
                              data-id="a96400a"
                              data-element_type="widget"
                              data-widget_type="icon.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-icon-wrapper">
                                  <div className="elementor-icon">
                                  <a aria-hidden="true" className="fab fa-twitter" href="https://www.twitter.com/yondacare" target="_blank" rel="noreferrer"></a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-9238f9f elementor-widget elementor-widget-heading"
                              data-id="9238f9f"
                              data-element_type="widget"
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <h2
                                  className="elementor-heading-title elementor-size-default"
                                >
                                  Twitter
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="make-column-clickable-elementor elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-b37e722"
                          style={{cursor: "pointer"}}
                          data-column-clickable="#"
                          data-column-clickable-blank="_self"
                          data-id="b37e722"
                          data-element_type="column"
                        >
                          <div
                            className="elementor-widget-wrap elementor-element-populated"
                          >
                            <div
                              className="elementor-element elementor-element-4600e11 elementor-view-default elementor-widget elementor-widget-icon"
                              data-id="4600e11"
                              data-element_type="widget"
                              data-widget_type="icon.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-icon-wrapper">
                                  <div className="elementor-icon">
                                  <a aria-hidden="true" className="fab fa-instagram" href="https://instagram.com/yondacare" target="_blank" rel="noreferrer"></a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-a408207 elementor-widget elementor-widget-heading"
                              data-id="a408207"
                              data-element_type="widget"
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <h2
                                  className="elementor-heading-title elementor-size-default"
                                >
                                  Instagram
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </section>
    )
}
export default Socials;